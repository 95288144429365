import React from "react";

import HeartSVG from "../../assets/svg-icons/heart";
import BookmarSVG from "../../assets/svg-icons/bookmark";
import share from "../../assets/images/ShareNetwork.svg";
import playlist from "../../assets/images/playlist-icon.svg";
import download from "../../assets/icons/Icons/download-simple.svg";

const ViewCount = props => {
    const {
      like, 
      views, 
      isLoading,
      likes, 
      translation, 
      handleClick, 
      isSavedContent, 
      handleSave,
      showShareModal,
      handleAddToPlaylist,  
      infoConference,
      point,
      isDownloaded,
      handleDownload,
    } = props;  
    const humanReadableDate = new Date(infoConference?.created);

    return (
      <div className="description__viewcount">
        <div className="description__viewcount__left">
          <div className="description__viewcount__left__paragraph">
            <p>
              {views ? `${translation.Conference_Views.replace("@number@", views)} ${infoConference?.created && `• ${humanReadableDate.toDateString()}`}` : ''}
            </p>
          </div>
        </div>
        <div className="description__viewcount__right">
          <div className="description__viewcount__right__paragraph">
  
          <p className={isDownloaded ? "video__downloaded--save" : "vido__notDownloadedun--unsave"}
          onClick={() => handleDownload()}
          >
            <img src={download} />
            <span>{translation.Conference_Download}</span>
          </p>

            <p 
              onClick={() => handleClick(!like)}
              className={`${like ? "description__recommended--like" : "description__recommended--unlike"}`}
            >
              {!isLoading ? 
              <>
                <HeartSVG />
                {point === "sm" ? 
                  <span>{likes !== "NaN" && likes}</span> : 
                  <span>{translation.Conference_Likes.replace("@number@", likes)}</span>
                }
              </> : 'Loading...'}
              {" "}
            </p>
            <p
              className={isSavedContent ? "video__saved" : "video__notSaved"}
              onClick={() => handleSave(isSavedContent)}
            >
              <BookmarSVG />
              <span>{translation.Conference_Save}</span>
            </p>
            <p onClick={showShareModal} className="view__count_conference_share">
              <img src={share} />
              <span>{translation.Conference_Share}</span>
            </p>
            <p onClick={handleAddToPlaylist} className="view__count_conference_playlist">
              <img src={playlist} />
              <span>{translation.Playlist_add}</span>
            </p>
          </div>
        </div>
      </div>
    );
  };
  
  export default ViewCount;