import React, { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ContentList from "../nestedList/contentJson/Content.json";
import { useDispatch, useSelector } from "react-redux";
import {
  postCategory,
  postSpeaker,
  addChannelService,
  patchPerson,
  putSpeaker,
  putChannel,
  putCategory,
  createTag,
  UpdateTag,
  DeleteTag,
} from "../../servicesRedux/GlobalService";
import requests from "../axios/Requests";
import { useEffect } from "react";
import {
  GetNewContentAction,
  GetAllCategoriesAction,
  GetAllSpeakersAction,
  GetAllChannelsAction,
  getAllTagsAction,
} from "../../redux/action/GlobalActions";
import ModalForm from "../modalForm/ModalForm";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import "./StylesCuration/CurationContentStyle.scss";
import { useNavigate } from "react-router-dom";
import { IoIosAddCircleOutline } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import Select from "react-select";
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Cookies from "js-cookie";
import { makeStyles } from "@material-ui/core/styles";

const tempLanguage = [
  { label: "English (United States)", value: "en-US" },
  { label: "French (France)", value: "fr-FR" },
  { label: "Spanish (Spain)", value: "es-ES" },
];

export default function CurationContent(props) {
  const { allTags, tab, reloadForm } = props;
  const dispatch = useDispatch();
  const {
    allCategories,
    allSpeakers,
    newContent,
    allContributors,
    allChannels,
  } = useSelector((state) => state.globalReducer);
  const navigate = useNavigate();

  const [subSubCategory, setSubSubCategory] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState([]);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [listSelectedSpeakers, SetlistSelectedSpeakers] = React.useState([]);
  const [showUpdateSpeaker, setShowUpdateSpeaker] = React.useState(false);
  const [selectedSpeaker, SetSelectedSpeaker] = React.useState("");
  const [selectedSubCategory, SetSelectedSubCategory] = React.useState("");
  const [selectedCategory, SetSelectedCategory] = React.useState("");
  const [selectedChannel, setselectedChannel] = React.useState("");
  const [canCreateSubCategory, setCanCreateSubCategory] = React.useState(true);
  const [canCreateSubSubCategory, setCanCreateSubSubCategory] =
    React.useState(true);

  const [showUpdateChannels, setShowUpdateChannels] = React.useState(false);
  const [modifyTag, setModifyTag] = React.useState(false);
  const [isNotDropDown, setIsNotDropDown] = useState([]);

  const [openModal, setoOpenModal] = React.useState({
    createCategory: false,
    createSubCategory: false,
    createSubSubCategory: false,
    createSpeaker: false,
    createTag: false,
    modifyTag: false,
  });
  const languageRef = React.createRef();
  const categoryRef = React.createRef();
  const subCategoryRef = React.createRef();
  const subSubCategoryRef = React.createRef();
  const speakersRef = React.createRef();
  const channelsRef = React.createRef();
  const tagsRef = React.createRef();
  const locationRef = React.createRef();

  let roles = Cookies.get("role");
  let isCuratorManager = roles.indexOf("Curator-Manager") > 0;
  const handleChangeTitle = (event) => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        title: event.target.value,
      })
    );
  };

  useEffect(() => {
    setSubCategory(newContent?.subCategory);
  }, [allCategories]);

  //force update selectedChannel, we will set it to null and then to the previous value
  useEffect(() => {
    const previousSelectedChannel = selectedChannel;
    setselectedChannel(null);
    setselectedChannel(previousSelectedChannel);
  }, [newContent?.channels]);
  const handleSelectCategory = (event) => {
    setCanCreateSubCategory(false);
    setCanCreateSubSubCategory(true);
    var id = event.value;

    var temp = allCategories.find((c) => c.parentId === id);

    if (!id || !temp) {
      return;
    }
    setSubCategory(temp.subCategory);
    SetSelectedCategory(temp);
    dispatch(
      GetNewContentAction({
        ...newContent,
        category: temp,
        subCategory: temp.subCategory,
        selectedSubCategory: null,
        selectedSubSubCategory: null,
      })
    );  
    subCategoryRef.current?.setValue([]);
    subSubCategoryRef.current?.setValue([]);
  };

  const handleSubmitCategoryModal = (category) => {
    let data = {
      label_En: category?.label,
      label_Fr: category?.label,
      parentId: category?.parentId,
      description: category?.description,
      locales: category?.locales,
    };
    postCategory(requests.CreateCategory, data).then((item) => {
      let temp = {
        category: item?.label_En,
        parentId: item?.id,
      };
      dispatch(
        GetNewContentAction({
          ...newContent,
          category: temp,
          subCategory: [],
        })
      );
      dispatch(GetAllCategoriesAction([...allCategories, temp]));
    });
  };
  const handleSubmitSubSubCategoryModal = (subCategory, category) => {
    let data = {
      label_En: category.label,
      label_Fr: category.label,
      parentId: subCategory?.id || subCategory[0]?.id || subCategory?.subCategoryId,
      description: category.description,
      locales: category?.locales,
    };

    postCategory(requests.CreateCategory, data).then((item) => {
      let newSubSubCategory = {
        contentCount: item.contentCount ?? 0,
        label: (item.label_En || item.label_Fr) ?? item.label,
        subCategoryId: item.subCategoryId ?? item.parentId,
        subSubCategory: item.subSubCategory ?? null,
        value: item.id,
      };
      setSubSubCategory([...subSubCategory, newSubSubCategory]);

      allCategories.map((itemCat, index) => {
        if (itemCat.parentId === newContent.category.parentId) {
          itemCat?.subCategory?.map((itemSub, compt) => {
            if (itemSub.subCategoryId === item.parentId) {
              itemSub.subSubCategory = [...subSubCategory, newSubSubCategory];
            }
          });
        }
      });
    });
  };
  const handleChangeSubCategory = (event) => {
    setCanCreateSubSubCategory(false);
    var id = event.value;
    if (!subCategory || !id) {
      return;
    }
    var temp = subCategory.find((sub) => sub.subCategoryId === id);
    if (temp) {
      setSubSubCategory(temp.subSubCategory);
      SetSelectedSubCategory(temp);
      dispatch(
        GetNewContentAction({
          ...newContent,
          selectedSubCategory: temp.label,
        })
      );
    }

    subSubCategoryRef.current?.setValue([]);
  };

  const handleChangeSubSubCategory = (event) => {
    if (event.value === undefined || !subSubCategory) {
      return;
    }
    dispatch(
      GetNewContentAction({
        ...newContent,
        selectedSubSubCategory: event.original,
      })
    );
  };

  const handleSubmitSubCategoryModal = (obj) => {
    let data = {
      label_En: obj?.label,
      label_Fr: obj?.label,
      parentId: newContent.category?.parentId,
      description: obj?.description,
      locales: obj?.locales,
    };

    postCategory(requests.CreateCategory, data).then((item) => {
      let newSubCategory = {
        label: item.label_En || item.label_Fr,
        subCategoryId: item.id,
        value: item.id,
        subSubCategory: [],
      };

      setSubCategory([...subCategory, newSubCategory]);

      allCategories.map((item, index) => {
        if (item.parentId === newContent.category.parentId) {
          item.subCategory = [...subCategory, newSubCategory];
        }
      });
    });
  };

  const handleSelectLanguage = (event) => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        language: event.value,
      })
    );
  };

  const handleSelectSpeaker = (event) => {
    setShowUpdateSpeaker(true);
    SetSelectedSpeaker(event.value);
  };
  const handleSubmitSpeakerModal = (speaker) => {
    return postSpeaker(requests.CreateSpeaker, speaker).then((item) => {
      const result = item;
      item = item.data;
      if (item.persons) {
        var newObjectSpeaker = item;

        newObjectSpeaker.value = item.id;
        newObjectSpeaker.label =
          item.persons.firstName + " " + item.persons.lastName;

        dispatch(GetAllSpeakersAction([...allSpeakers, newObjectSpeaker]));
      }

      if (newContent.speaker) {
        var toUpdate = newContent.speaker;

        toUpdate.push(item);

        dispatch(
          GetNewContentAction({
            ...newContent,
            speaker: toUpdate,
          })
        );
      } else {
        var toUpdate = [item];

        dispatch(
          GetNewContentAction({
            ...newContent,
            speaker: toUpdate,
          })
        );
      }
      return result;
    });
  };

  const handleSubmitChannelModal = (channel) => {
    channel.active = true;
    return addChannelService(requests.CreateChannel, channel).then((item) => {
      const result = item;
      item = item.data;
      if (item) {
        let newObjectChannel = item;

        newObjectChannel.value = item.id;
        newObjectChannel.label = item.name;
        dispatch(GetAllChannelsAction([...allChannels, newObjectChannel]));
      }

      dispatch(
        GetNewContentAction({
          ...newContent,
          Channel: item,
        })
      );
      return result;
    });
  };

  const handleSubmitUpdateSpeakerModal = (speaker) => {

    const body = {
      firstName: speaker.firstName,
      lastName: speaker.lastName,
      title: speaker.title,
      biography: speaker.biography,
    };

    if (speaker.avatar && speaker.image) {
      body.avatar = speaker.avatar;

      return putSpeaker(requests.UpdateSpeaker, speaker.id, body).then((item) => {
        const result = item;
        item = item.data.persons;
        const current = allSpeakers.find((s) => s.id === speaker.id);
        if (current) {
          current.persons = item;
          current.image = item.avatar;
          current.label = item.firstName + " " + item.lastName;
          dispatch(GetAllSpeakersAction([...allSpeakers]));
        }
        return result;
      });
    }
    else if (speaker.avatar) {
      body.avatar = speaker.avatar;

      return putSpeaker(requests.UpdateSpeaker, speaker.id, body).then((item) => {
        const result = item;
        item = item.data.persons;
        const current = allSpeakers.find((s) => s.id === speaker.id);
        if (current) {
          current.persons = item;
          current.image = item.avatar;
          current.label = item.firstName + " " + item.lastName;
          dispatch(GetAllSpeakersAction([...allSpeakers]));
        }
        return result;
      });

    } else {
      patchPerson(requests.UpdatePerson, speaker.personId, body).then((item) => {
        const current = allSpeakers.find((s) => s.id === speaker.id);
        if (current) {
          current.persons = item;
          current.label = item.firstName + " " + item.lastName;
          dispatch(GetAllSpeakersAction([...allSpeakers]));
        }
      });
    }
  };

  const handleSubmitUpdateChannelModal = (channel) => {
    const body = {
      name: channel.name,
      description: channel.description,
      avatar: channel.avatar,
      active: channel.active ?? true,
    };

    // Return the promise chain from the function
    return putChannel(requests.UpdateChannel, channel.id, body).then((item) => {
      const toUpdate = { ...item.data.data, value: item.data.data.id, label: item.data.data.name, avatar : item.data.data.avatar};

      dispatch(
        GetNewContentAction({
          ...newContent,
          channels: [toUpdate],
        })
      );

      // Return the value you want to resolve with
      return item.data; // Assuming you want to resolve with `toUpdate`
    });
  };


  const handleSubmitUpdateCategoryModal = (category) => {

    const body = {
      label: category.label,
      sponsoredLogoExternalLink: category.sponsoredLogoExternalLink,
      sponsoredLogoPath: category.sponsoredLogoPath,
      
    };
    // Return the promise chain from the function
    return putCategory(requests.UpdateCategory, category.id, body).then((item) => {
      const toUpdate = { ...item.data.data,value: item.data.data.id ,label: item.data.data.label, sponsoredLogoExternalLink : item.data.data.sponsoredLogoExternalLink, sponsoredLogoPath : item.data.data.sponsoredLogoPath};

      dispatch(
        GetNewContentAction({
          ...newContent,
          category: [toUpdate],
        })
      );
      dispatch(GetAllCategoriesAction([...allCategories, toUpdate]));
      // Return the value you want to resolve with
      return item.data; // Assuming you want to resolve with `toUpdate`
    });
  };



  function handleAddition(tag) {
    var check = selectedTags?.find((st) => st.label === tag.label);
    if (!check) {
      setSelectedTags([...selectedTags, tag]);
    }
  }

  function handleDelete(e) {
    setSelectedTags((prev) => prev.filter((tag, index) => index !== e));
  }
  const handleSelectLocation = (event) => {
    if (event.value) {
      dispatch(
        GetNewContentAction({
          ...newContent,
          location: event.value,
        })
      );
    }
  };

  const addSpeaker = () => {
    var temp = newContent.speaker !== undefined ? newContent.speaker : [];
    var check = temp?.find((c) => c.id === selectedSpeaker);
    if (!check) {
      temp.push(allSpeakers.find((c) => c.id === selectedSpeaker));
      SetlistSelectedSpeakers(temp);
      dispatch(
        GetNewContentAction({
          ...newContent,
          speaker: temp,
        })
      );
    }
  };

  const addChannel = () => {
    // var temp = newContent.Channel !== undefined ? newContent.Channel : {};
    var check = allChannels?.find((c) => c.id === selectedChannel);
    if (check) {
      dispatch(
        GetNewContentAction({
          ...newContent,
          Channel: check,
        })
      );
    }
  };

  const handleDeleteSpeaker = (event, id) => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        speaker: newContent.speaker.filter((c) => c.id !== id),
      })
    );
  };

  const handleSubmitTag = (tag) => {
    if (tag.label === undefined || tag.label === {}) {
      return
    }

    let data = {
      label: tag?.label,
      label_Fr: tag?.label_fr,
    };
    createTag(requests.CreateTags, data).then((item) => {
      if (item) {
        let newObjectTag = {};
        let newAllTags = allTags.filter((tag) => tag.value !== item.id);
        newObjectTag.value = item.id;
        newObjectTag.label = item.label;
        newObjectTag.label_fr = item.label_Fr;
        newObjectTag.isNew = item.isNew;
        newObjectTag.canModify = true;
        newAllTags.push(newObjectTag);
        dispatch(getAllTagsAction(newAllTags));
        setSelectedTags([...selectedTags, newObjectTag])
        setModifyTag(newObjectTag)
      }
    })
      .catch((error) => {
        if (error.response.status === 409) {
          alert("Tag already exists");
        }
      });
  };

  const handleModifyTag = (tag) => {
    if (tag.label === undefined || tag.label === {}) {
      return
    }

    let data = tag?.label_fr ? {
      label_Fr: tag?.label_fr,
    } : {
      label: tag?.label,
    };

    UpdateTag(requests.UpdateTags, tag.value, data).then((item) => {
      if (item) {
        let newObjectTag = {};
        let newAllTags = allTags.filter((tag) => tag.value !== item.id);

        newObjectTag.value = item.id;
        newObjectTag.label = item.label;
        newObjectTag.label_fr = item.label_Fr;
        newObjectTag.isNew = item.isNew;
        newObjectTag.canModify = (modifyTag !== undefined);
        newAllTags.push(newObjectTag);
        dispatch(getAllTagsAction(newAllTags));
        //if newObjectTag.value is in selectedTags state, replace it
        let newSelectedTags = selectedTags.map((tag) => {
          if (tag.value === newObjectTag.value) {
            return newObjectTag;
          }
          return tag;
        });
        setSelectedTags(newSelectedTags);
        setModifyTag(newObjectTag)
      }
    })
      .catch((error) => {
        if (error.response.status === 409) {
          alert("Tag already exists");
        }
      });
  };

  const handleDeleteTag = (item) => {
    setSelectedTags(selectedTags.filter((st) => st.label !== item));
  };

  const handleSelectChannels = (event) => {
    if (!event.value) return;
    setShowUpdateChannels(true);
    setselectedChannel(event.value);
    dispatch(
      GetNewContentAction({
        ...newContent,
        Channel: event,
      })
    );
  };

  const handleDeleteChannel = (event, id) => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        Channel: {},
      })
    );
  };

  function sortSPCO(speakercontributor) {
    speakercontributor.sort(function (a, b) {
      const nameA = (
        a.persons?.firstName ??
        "" + a.persons?.lastName ??
        ""
      ).replaceAll(" ", "");
      const nameB = (
        b.persons?.firstName ??
        "" + b.persons?.lastName ??
        ""
      ).replaceAll(" ", "");
      return nameA.localeCompare(nameB);
    });
  }
  sortSPCO(allSpeakers);
  // sortSPCO(allChannels);

  useEffect(() => {
    if (selectedTags?.length > 0) {
      dispatch(
        GetNewContentAction({
          ...newContent,
          keyWord: selectedTags,
        })
      );
    }
  }, [selectedTags]);

  useEffect(() => {
    languageRef.current?.setValue([]);
    categoryRef.current?.setValue([]);
    subCategoryRef.current?.setValue([]);
    subSubCategoryRef.current?.setValue([]);
    tagsRef.current?.setValue([]);
    locationRef.current?.setValue([]);
    channelsRef.current?.setValue([]);
    allSpeakers.current?.setValue([]);
    setSelectedTags([]);
    SetSelectedSpeaker([]);

    setShowUpdateChannels(false);
    setShowUpdateSpeaker(false);
  }, [reloadForm]);




  const Tags = () => {

    function modifyThisTag(data) {
      setModifyTag(data)
      setoOpenModal((prev) => ({ ...prev, modifyTag: true }))

    }
    const MyIconButton = (props) => {
      const { data } = props;
      return (
        <div className={"icon_button"} onClick={() => modifyThisTag(data)}>
          <CreateRoundedIcon />
        </div>
      )
    };
    return (
      <>
        {selectedTags?.map((item, index) => {
          return (
            <Stack direction="row" spacing={1} key={index}>
              {item?.isNew && modifyTag !== null && item?.canModify && !(isNotDropDown && isNotDropDown?.find(elem => elem === item?.label) !== undefined) ? (
                <Chip
                  label={item?.label}
                  variant="outlined"
                  sx={{ margin: "5px" }}
                  onDelete={(e) => {
                    handleDeleteTag(item?.label);
                  }}
                  icon={<MyIconButton data={item} />}
                />
              ) : (
                <Chip
                  label={item?.label}
                  variant="outlined"
                  sx={{ margin: "5px" }}
                  onDelete={(e) => {
                    handleDeleteTag(item?.label);
                  }}
                />
              )}

            </Stack>
          );
        })}
      </>
    );

  };
  const SelectTags = (props) => {
    const { allTags } = props;

    function handleDeleteTagForever(data) {
      if (window.confirm("Are you sure you want to delete this tag?")) {
        // Add the functionality to delete the tag here
        DeleteTag(requests.DeleteTags, data.value)
          .then((item) => {
            if (item) {
              const updatedList = allTags.filter((c) => c.value !== item.id);
              dispatch(getAllTagsAction(updatedList));

              //delete from selectedTags
              const updatedSelectedTags = selectedTags.filter((c) => c.value !== item.id);
              setSelectedTags(updatedSelectedTags);
            }
          })
      }
    }

    function modifyThisTag(data) {
      setModifyTag(data)
      setoOpenModal((prev) => ({ ...prev, modifyTag: true }))
      setIsNotDropDown([...isNotDropDown, data.label])
    }

    const MyIconButton = (props) => {
      const { data } = props;
      return (
        <>
          {isCuratorManager && (
            <>
              <div
                className={"icon_button"}
                onClick={() => modifyThisTag(data)}
              >
                <CreateRoundedIcon />
              </div>
              <div
                className={"icon_button delete"}
                onClick={() => handleDeleteTagForever(data)}
              >
                <DeleteRoundedIcon />
              </div>
            </>
          )}
        </>
      );
    };
    return (
      <div style={{ width: "95%", fontWeight: "700" }}>
        <Select
          options={allTags}
          placeholder={"Search for a Tag"}
          components={{
            Option: (props) => {
              return (
                <div className="tags_icon_modify">
                  <MenuItem
                    onClick={() => {
                      handleAddition(props.data);
                    }}
                  >
                    {props.data.label}   ({props.data.contentCount})
                  </MenuItem>
                  {(props.data.label_fr === null || props.data.label_fr === "" || props.data.label_fr === undefined) && <MyIconButton data={props.data} />}
                </div>
              );
            }
          }}
        />
      </div>
    );
  }

  const displaySpeakers = () => {
    return (
      <div style={{ width: "95%", fontWeight: "700" }}>
        <Select
          onChange={handleSelectSpeaker}
          options={allSpeakers}
          placeholder={"Search a Speaker ..."}
        />
      </div>
    );
  };

  useEffect(() => {
    displaySpeakers();
  }, [allSpeakers]);

  useEffect(() => {
    if (newContent?.generateOpenAI === true) {
      const timestamp = new Date().toISOString();
      const randomFiveDigitNumber = Math.floor(10000 + Math.random() * 90000);

      dispatch(
        GetNewContentAction({
          ...newContent,
          title: `[GENERATED TITLE - ${timestamp} - ${randomFiveDigitNumber}]`,
          fullSummary: "[GENERATED DESCRIPTION]",
          keyWord: allTags[0],
        })
      );
      const aiTag = allTags[0];
      setSelectedTags([aiTag]);
    }
    else {
      dispatch(
        GetNewContentAction({
          ...newContent,
          title: "",
          fullSummary: "",
          keyWord: [],
        })
      );
      setSelectedTags([]);
    }
  }, [newContent?.generateOpenAI, allTags]);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
          width: "100%",
          height: "100%",
        },
      }}
      autoComplete="off"
      noValidate
    >
      <Paper elevation={5} sx={{ padding: 2 }} className="parent_paper">
        <Typography
          sx={{ fontSize: 16, fontWeight: 800 }}
          color="text.secondary"
          gutterBottom
        >
          Content : {" " + tab}
        </Typography>
        <Grid container>
          <Grid item xs={2} sx={{ display: "flex", }}>
            <Typography
              sx={{
                fontSize: 18,
                marginLeft: 5,
                marginBottom: 0,
                display: 'flex',
                alignItems: 'center',
                height: 'YourDesiredHeight' // Specify the height as needed
              }}
              color="text.secondary"
              gutterBottom
            >
              Generate Title, Tags, Description with AI :
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ display: "flex" }}>
            <Checkbox checked={newContent?.generateOpenAI || false} onClick={() => {
              dispatch(
                GetNewContentAction({
                  ...newContent,
                  generateOpenAI: !newContent?.generateOpenAI,
                })
              );
            }} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2} sx={{ display: "flex" }}>
            <Typography
              sx={{ fontSize: 18, marginLeft: 5 }}
              color="text.secondary"
              gutterBottom
            >
              Title :
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="outlined-basic"
              label={!newContent.title ? "Title" : ""}
              value={newContent.title ?? ""}
              variant="outlined"
              sx={{ width: "95%" }}
              size="small"
              onChange={handleChangeTitle}
            />
            {/* } */}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2} sx={{ display: "flex" }}>
            <Typography
              sx={{ fontSize: 18, marginLeft: 5 }}
              color="text.secondary"
              gutterBottom
            >
              Language :
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <div style={{ width: "95%", fontWeight: "700" }}>
              <Select
                onChange={handleSelectLanguage}
                options={tempLanguage}
                placeholder={"Search for a Language ..."}
                ref={languageRef}
                key={"languageRef"}
              />
            </div>
          </Grid>
        </Grid>

        {!!selectedTags?.length && (
          <Grid container>
            <Grid item xs={2} sx={{ display: "flex", flexWrap: "wrap" }}>
              <Typography
                sx={{ fontSize: 18, marginLeft: 5 }}
                color="text.secondary"
                gutterBottom
              >
                Selected Tags :
              </Typography>
            </Grid>
            <Grid item xs={8} style={{ display: "flex", flexWrap: "wrap" }}>
              <Tags includedInDashboard={false} />
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={2} sx={{ display: "flex" }}>
            <Typography
              sx={{ fontSize: 18, marginLeft: 5 }}
              color="text.secondary"
              gutterBottom
            >
              Tags :
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <SelectTags allTags={allTags} />
          </Grid>
          {isCuratorManager &&
            <div style={{ marginLeft: "-40px" }}>
              <Chip
                onClick={() =>
                  setoOpenModal((prev) => ({ ...prev, createTag: true }))
                }
                icon={<IoIosAddCircleOutline size={25} />}
                label="New"
              />
            </div>}


          <ModalForm
            open={openModal.modifyTag}
            onClose={() =>
              setoOpenModal((prev) => ({ ...prev, modifyTag: false }))
            }
            contentType={"modifytags"}
            title={"Modify Tag"}
            isNewTag={modifyTag !== undefined ? false : true}
            handleSubmitModal={(
              category,
              subCategory,
              subSubCategory,
              speaker,
              tags
            ) => handleModifyTag(tags)}
            modifyTag={modifyTag}
            setModifyTag={setModifyTag}
          />

          <ModalForm
            open={openModal.createTag}
            onClose={() =>
              setoOpenModal((prev) => ({ ...prev, createTag: false }))
            }
            contentType={"createtags"}
            title={"Create Tag"}
            isNewTag={false}
            handleSubmitModal={(
              category,
              subCategory,
              subSubCategory,
              speaker,
              tags
            ) => handleSubmitTag(tags)}
            setModifyTag={setModifyTag}
          />
        </Grid>

        <Grid container>
          <Grid item xs={2} sx={{ display: "flex" }}>
            <Typography
              sx={{ fontSize: 18, marginLeft: 5 }}
              color="text.secondary"
              gutterBottom
            >
              Category:
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <div style={{ width: "95%", fontWeight: "700" }}>
              <Select
                onChange={handleSelectCategory}
                options={allCategories?.map(option => ({
                  label: option.label + (option.contentCount !== undefined ? " (" + option.contentCount + ")" : "0"),
                  category: option.category,
                  value: option.parentId,
                  parentId: option.parentId,
                  contentCount: option.contentCount,
                  subCategory: option.subCategory,
                  sponsoredLogoPath: option.SponsoredLogoPath,
                  sponsoredLogoExternalLink: option.SponsoredLogoExternalLink,
                }))}
                placeholder={"Search for a Category ..."}
                ref={categoryRef}
                key={"categoryRef"}
              />
            </div>
          </Grid>
          <ModalForm
            open={openModal.createCategory}
            onClose={() =>
              setoOpenModal((prev) => ({ ...prev, createCategory: false }))
            }
            contentType={"category"}
            title={"Create Category"}
            handleSubmitModal={(
              category,
              subCategory,
              subSubCategory,
              speaker
            ) => handleSubmitCategoryModal(category)}
          />
       
        {isCuratorManager && (
            <div style={{ marginTop: "2px", marginLeft: "-40px" }}>
              <Chip
                onClick={() =>
                  setoOpenModal((prev) => ({ ...prev, updateCategory: true }))
                }
                icon={<FaEdit size={20} />}
                label="Update"
              />
            </div>
          )}
        

          <ModalForm
            open={openModal.updateCategory}
            onClose={() =>
              setoOpenModal((prev) => ({ ...prev, updateCategory: false }))
            }
           // data={newContent?.channels != undefined ? newContent?.channels[0] :  allChannels.find((c) => c.id === selectedChannel) }
           data={selectedCategory }
          
         //   data={newContent?.category != undefined ? newContent?.category[0] :  allCategories.find((c) => c.id ===selectedCategory) }
            contentType={"categoryUpdate"}
            title={"Category Update"}
            handleSubmitModal={(
              category,
              subCategory,
              subSubCategory,
              channel
            ) => handleSubmitUpdateCategoryModal(category)}
          />  
              </Grid> 

        <Grid container>
          <Grid item xs={2} sx={{ display: "flex" }}>
            <Typography
              sx={{ fontSize: 18, marginLeft: 5 }}
              color="text.secondary"
              gutterBottom
            >
              SubCategory :
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <div style={{ width: "95%", fontWeight: "700" }}>
              <Select
                onChange={handleChangeSubCategory}
                options={subCategory?.map(option => ({
                  label: option.label + (option.contentCount !== undefined ? " (" + option.contentCount + ")" : "0"),
                  value: option.subCategoryId,
                  contentCount: option.contentCount,
                  subCategoryId: option.subCategoryId,
                  subSubCategory: option.subSubCategory,
                }))}
                placeholder={"Search for a Sub-Category ..."}
                ref={subCategoryRef}
                key={"subCategory"}
              />
            </div>
          </Grid>
          <div style={{ marginLeft: "-40px" }}>
            <Chip
              onClick={() =>
                setoOpenModal((prev) => ({ ...prev, createSubCategory: true }))
              }
              icon={<IoIosAddCircleOutline size={25} />}
              label="New"
              disabled={isCuratorManager === false ? true : canCreateSubCategory}
            />
          </div>

          <ModalForm
            open={openModal.createSubCategory}
            onClose={() =>
              setoOpenModal((prev) => ({ ...prev, createSubCategory: false }))
            }
            contentType={"subCategory"}
            title={"Create Subcategory"}
            handleSubmitModal={(
              category,
              subCategory,
              subSubCategory,
              speaker
            ) => handleSubmitSubCategoryModal(subCategory)}
          />
        </Grid>

        <Grid container>
          <Grid item xs={2} sx={{ display: "flex" }}>
            <Typography
              sx={{ fontSize: 18, marginLeft: 5 }}
              color="text.secondary"
              gutterBottom
            >
              Sub-SubCategory :
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <div style={{ width: "95%", fontWeight: "700" }}>
              <Select
                onChange={handleChangeSubSubCategory}
                options={subSubCategory?.map(option => ({
                  label: option.label + (option.contentCount !== undefined ? " (" + option.contentCount + ")" : "0"),
                  original: option.label,
                  value: option.subCategoryId ?? option.value,
                  contentCount: option.contentCount ? option.contentCount : null,
                  subCategoryId: option.subCategoryId,
                  subSubCategory: option.subSubCategory,
                }))}
                placeholder={"Search for a Sub-Sub-Category ..."}
                ref={subSubCategoryRef}
                key={"subSubCategory"}
              />
            </div>
          </Grid>
          <div style={{ marginLeft: "-40px" }}>
            <Chip
              onClick={() =>
                setoOpenModal((prev) => ({
                  ...prev,
                  createSubSubCategory: true,
                }))
              }
              icon={<IoIosAddCircleOutline size={25} />}
              label="New"
              disabled={isCuratorManager === false ? true : canCreateSubSubCategory}
            />
          </div>

          <ModalForm
            open={openModal.createSubSubCategory}
            onClose={() =>
              setoOpenModal((prev) => ({
                ...prev,
                createSubSubCategory: false,
              }))
            }
            contentType={"subsubCategory"}
            title={"Create Sub-Subcategory"}
            handleSubmitModal={(
              category,
              subCategory,
              subSubCategory,
              speaker
            ) =>
              handleSubmitSubSubCategoryModal(
                selectedSubCategory,
                subSubCategory
              )
            }
          />
        </Grid>
        {newContent?.speaker?.length > 0 && (
          <Grid container>
            <Grid item xs={2} sx={{ display: "flex" }}>
              <Typography
                sx={{ fontSize: 18, marginLeft: 5 }}
                color="text.secondary"
                gutterBottom
              >
                Selected Speakers :
              </Typography>
            </Grid>
            <Grid item xs={8} style={{ display: "flex" }}>
              {newContent?.speaker?.map((item, index) => {
                return (
                  <Stack direction="row" spacing={1} key={index}>
                    <Chip
                      label={
                        item?.persons?.firstName + " " + item?.persons?.lastName
                      }
                      variant="outlined"
                      onDelete={(e) => {
                        handleDeleteSpeaker(e, item.id);
                      }}
                    />
                  </Stack>
                );
              })}
            </Grid>
          </Grid>
        )}

        <Grid container>
          <Grid item xs={2} sx={{ display: "flex" }}>
            <Typography
              sx={{ fontSize: 18, marginLeft: 5 }}
              color="text.secondary"
              gutterBottom
            >
              Speakers :
            </Typography>
          </Grid>
          {
            <Grid item xs={8}>
              {displaySpeakers()}
            </Grid>
          }

          <div
            style={{
              marginLeft: "-40px",
              marginTop: "2px",
              marginRight: "10px",
            }}
          >
            <Chip
              onClick={() =>
                setoOpenModal((prev) => ({ ...prev, createSpeaker: true }))
              }
              icon={<IoIosAddCircleOutline size={25} />}
              label="New"
            />
          </div>

          {showUpdateSpeaker && (
            <div style={{ marginTop: "2px" }}>
              <Chip
                onClick={addSpeaker}
                icon={<TiTick size={25} />}
                label="Add"
              />
            </div>
          )}

          {showUpdateSpeaker && (
            <div style={{ marginTop: "2px", marginLeft: "10px" }}>
              <Chip
                onClick={() =>
                  setoOpenModal((prev) => ({ ...prev, updateSpeaker: true }))
                }
                icon={<FaEdit size={20} />}
                label="Update"
              />
            </div>
          )}

          <ModalForm
            open={openModal.createSpeaker}
            onClose={() =>
              setoOpenModal((prev) => ({ ...prev, createSpeaker: false }))
            }
            contentType={"speaker"}
            title={"Create Speaker"}
            handleSubmitModal={(
              category,
              subCategory,
              subSubCategory,
              speaker
            ) => handleSubmitSpeakerModal(speaker)}
          />
          <ModalForm
            open={openModal.createChannel}
            onClose={() =>
              setoOpenModal((prev) => ({ ...prev, createChannel: false }))
            }
            contentType={"channel"}
            title={"Create Content Owner"}
            handleSubmitModal={(
              category,
              subCategory,
              subSubCategory,
              channel
            ) => handleSubmitChannelModal(channel)}
          />

          <ModalForm
            open={openModal.updateSpeaker}
            onClose={() =>
              setoOpenModal((prev) => ({ ...prev, updateSpeaker: false }))
            }
            data={allSpeakers.find((c) => c.id === selectedSpeaker)}
            contentType={"speakerUpdate"}
            title={"Update Speaker"}
            handleSubmitModal={(
              category,
              subCategory,
              subSubCategory,
              speaker
            ) => handleSubmitUpdateSpeakerModal(speaker)}
          />
        </Grid>
        <Grid container>
          <Grid item xs={2} sx={{ display: "flex" }}>
            <Typography
              sx={{ fontSize: 18, marginLeft: 5 }}
              color="text.secondary"
              gutterBottom
            >
              Content Owner:
            </Typography>
          </Grid>
          {
            <Grid item xs={8}>
              <div style={{ width: "95%", fontWeight: "700" }}>
                <Select
                  onChange={handleSelectChannels}
                  options={allChannels}
                  value={
                    newContent?.Channel
                      ? newContent?.Channel
                      : allChannels.find((ele) => ele.id === selectedChannel)
                  }
                  placeholder={"Search a Content Owner ..."}
                  ref={channelsRef}
                  key={"channelsRef"}
                />
              </div>
            </Grid>
          }

          {isCuratorManager && (

            <div
              style={{
                marginLeft: "-40px",
                marginTop: "2px",
                marginRight: "10px",
              }}
            >
              <Chip
                onClick={() =>
                  setoOpenModal((prev) => ({ ...prev, createChannel: true }))
                }
                icon={<IoIosAddCircleOutline size={25} />}
                label="New"
              />
            </div>
          )}

          {false && isCuratorManager && (
            <div style={{ marginTop: "2px" }}>
              <Chip
                onClick={addChannel}
                icon={<TiTick size={25} />}
                label="Add"
              />
            </div>
          )}

          {isCuratorManager && (
            <div style={{ marginTop: "2px", marginLeft: "10px" }}>
              <Chip
                onClick={() =>
                  setoOpenModal((prev) => ({ ...prev, updateChannel: true }))
                }
                icon={<FaEdit size={20} />}
                label="Update"
              />
            </div>
          )}
          <ModalForm
            open={openModal.createChannel}
            onClose={() =>
              setoOpenModal((prev) => ({ ...prev, createChannel: false }))
            }
            contentType={"channel"}
            title={"Create Content Owner"}
            handleSubmitModal={(
              category,
              subCategory,
              subSubCategory,
              channel
            ) => handleSubmitChannelModal(channel)}
          />

          <ModalForm
            open={openModal.updateChannel}
            onClose={() =>
              setoOpenModal((prev) => ({ ...prev, updateChannel: false }))
            }
            data={newContent?.channels != undefined ? newContent?.channels[0] :  allChannels.find((c) => c.id === selectedChannel) }
            contentType={"channel"}
            title={"Channel Update"}
            handleSubmitModal={(
              category,
              subCategory,
              subSubCategory,
              channel
            ) => handleSubmitUpdateChannelModal(channel)}
          />
        </Grid>



        <Grid container>
          <Grid item xs={2} sx={{ display: "flex" }}>
            <Typography
              sx={{ fontSize: 18, marginLeft: 5 }}
              color="text.secondary"
              gutterBottom
            >
              Location :
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <div style={{ width: "95%", fontWeight: "700" }}>
              <Select
                onChange={handleSelectLocation}
                options={ContentList.Pays}
                placeholder={"Search for a Location"}
                ref={locationRef}
                key={"locationRef"}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
