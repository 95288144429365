// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Row component *                                               *
 * ?                  Row component is one of the biggest component used accros many pages                      *
 * ?                  Used in : homepage/recommendations/watch later/search                                     *
 * ?                  It's working with "swiper" to render every conferences that the backend gives             *
 * ?                  It also contains the possibility to add the choosen conference into our watch later       *
 * ?                  The swiper got many possible settings, check the library in google to change how it works *
 */
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useState, useRef, memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import VideoCard from "../videoCard/VideoCard";
import requests from "../../components/axios/Requests";
import { getRow } from "../../servicesRedux/HomepageService";
import { getUserWatchLaterList } from "../../servicesRedux/DownloadedService";
import { getContentBySpeaker } from "../../servicesRedux/ChannelService";
import {
  LoadNextAction,
  HasLoadedAction,
} from "../../redux/action/PaginationAction";

import { GetCategoryFilter } from "../../redux/action/CategoryFilterAction";

import { SetLoadingStatesHomepage, SetLoadingStatesSubscriptions } from "../../redux/action/MediaTypeAction";
import { getPlaylistContent, GetContentSharedPlaylist } from "../../servicesRedux/PlaylistService";

import "swiper/swiper-bundle.css";
import 'swiper/scss';
import "./Row.scss";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/grid";

import "../../theme/row/Row.scss";

const Row = props => {
  const {
    title,
    Logo,
    contentType,
    appearanceType,
    type,
    person,
    channel,
    playlistId,
    playlistDeleteContent,
    typePlayList,
    isPlayList,
    playListCatType,
    mediaType,
    category,
    callback,
    categoryFilter,
    page,
    contentOwner,
    setHasContentRow,
    sponsoredLogoPath,
    sponsoredLogoExternalLink
  } = props;
  const userId = Cookies.get("userID");
  const dispatch = useDispatch();
  const [requestReturn, setRequestReturn] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [load, setLoad] = useState(false);
  const prevNavbarFilter = useRef("");
  const [content, setContent] = useState([]);
  const { navbarFilter } = useSelector((state) => state.globalReducer);
  const { loadNext } = useSelector((state) => state.paginationReducer);
  const nbSlidesRef = useRef(0);
  const activeIndexRef = useRef(0);
  const prevParams = useRef();
  const prevMediaType = useRef(mediaType);
  const [, setIsLoading] = useState(false);
  const [duplicateCallGuard, setDuplicateCallGuard] = useState(true);
  const navigate = useNavigate();
  const { translation } = useSelector(state => state.languageReducer);
  const { userRedux } = useSelector(state => state.authentificationReducer);

  /**
   * ? HandleSave is there to add a video in the watchLater playlist of the logged in User
   * ? It will add the video in the database and in the playlist watch later
   */
  const pageParams = {
    limit: 10,
    page: totalPages >= pageNumber ? pageNumber : 1,
  };

  useEffect(() => {
      const tempContent = content.filter(toRemove => toRemove.contentId !== playlistDeleteContent?.hasBeenDeletedId);
      setContent(tempContent);
  }, [playlistDeleteContent?.hasBeenDeletedId]);

  useEffect(() => {
    if (duplicateCallGuard) {
      if (contentType === "subscription") {
        if (mediaType !== prevMediaType.current) {
          pageParams.page = 1;
          setPageNumber(1);
          setTotalPages(1);
        }
        const comparison = JSON.stringify(prevParams.current) !== JSON.stringify(pageParams);  
        if (totalPages ? totalPages >= pageNumber : true && comparison && mediaType === prevMediaType.current) {
          setIsLoading(true);
            dispatch(HasLoadedAction(false));
            getRow(requests.GetContent, type, pageParams, category, mediaType, userRedux.preferredLanguageIds).then(item => {
              setRequestReturn(item);
              dispatch(SetLoadingStatesHomepage(type));
              category && dispatch(SetLoadingStatesSubscriptions(category));
              setIsLoading(false);
            });
          }
          prevParams.current = pageParams;
        }
        setDuplicateCallGuard(false);
      }
  }, []);  

  useEffect(() => {
    if (duplicateCallGuard) {
      if (mediaType !== prevMediaType.current) {
        pageParams.page = 1;
        setPageNumber(1);
        setTotalPages(1);
      }
      const comparison = JSON.stringify(prevParams.current) !== JSON.stringify(pageParams);
      if (totalPages ? totalPages >= pageNumber : true && comparison && mediaType === prevMediaType.current) {
        switch (contentType) {                    
          case "downloaded":
            dispatch(HasLoadedAction(false));
            getUserWatchLaterList(requests.GetMeWatchlater, pageParams).then(item => {
              setRequestReturn(item);
              return;
            });
            break;
          case "homepage":
            switch (type) {
              case "Categories":  
                setIsLoading(true);
                dispatch(HasLoadedAction(false));
                getRow(requests.GetContent, type, pageParams, category, mediaType, userRedux.preferredLanguageIds).then(item => {
                  setRequestReturn(item);
                  dispatch(SetLoadingStatesHomepage(type));
                  category && dispatch(SetLoadingStatesSubscriptions(category));
                  setIsLoading(false);
                });
                break;
              case "TopTcVideo":
                dispatch(HasLoadedAction(false));
                getRow(requests.GetContent, type, pageParams, categoryFilter, mediaType, userRedux.preferredLanguageIds).then(item => {
                  setRequestReturn(item);
                  dispatch(SetLoadingStatesHomepage(type));
                });
                break;
              case "NewOnTopCream":
                dispatch(HasLoadedAction(false));
                getRow(requests.GetContent, type, pageParams, categoryFilter, mediaType, userRedux.preferredLanguageIds).then(item => {
                  setRequestReturn(item);
                  dispatch(SetLoadingStatesHomepage(type));
                });
                break;
              default:
                dispatch(HasLoadedAction(false));
                getRow(requests.GetContent, type, pageParams, categoryFilter, mediaType, userRedux.preferredLanguageIds).then(item => {
                  setRequestReturn(item);
                  dispatch(SetLoadingStatesHomepage(type));
                });
            }
            break;
          case "channel":
            dispatch(HasLoadedAction(false));
            getContentBySpeaker(
              contentOwner ? requests.GetContentByChannel : requests.GetContentBySpeaker,
              pageParams,
              person?.id ?? channel?.contributorId
            ).then(item => {
              function transform(obj) {
                const newObj = {
                  pageSize: obj.pageSize,
                  currentPage: obj.currentPage,
                  totalItems: obj.totalItems,
                  totalPages: obj.totalPages,
                  data: obj.data[0].contentResponse
                };
                const tagsResponses = obj.data[0].tagsResponses;
                return { newObj, tagsResponses };
              }
              const { newObj, tagsResponses } = transform(item);
              setRequestReturn(newObj);
              callback(tagsResponses);
            });
            break;
          case "NewOnTopCream":
            dispatch(HasLoadedAction(false));
            getRow(requests.GetContent, contentType, pageParams).then(item => setRequestReturn(item));
            break;
          case "ViewAll":
            dispatch(HasLoadedAction(false));
            setIsLoading(true);
            getRow(requests.GetContent, type, pageParams).then(item => {
              setRequestReturn(item);
              setIsLoading(false);
            });
            break;
          case "Recommended":
            dispatch(HasLoadedAction(false));
            getRow(requests.GetContent, contentType, pageParams).then(item => setRequestReturn(item));
            break;
          case "Playlist":
              dispatch(HasLoadedAction(false));
              if (playlistId) {
                if (playListCatType == "my") {
                  getPlaylistContent(
                    requests.GetMyPlaylistContents,
                    playlistId,
                    pageParams,
                    mediaType
                  ).then(item => setRequestReturn(item));
                } else {
                  const mediaTypes = "&MediaType=1&MediaType=2&MediaType=3";
                  GetContentSharedPlaylist(
                    requests.GetContentSharedPlaylist,
                    playlistId,
                    pageParams,
                    mediaTypes
                  ).then(item => setRequestReturn(item));
                }
              }            
            break;
          default:
            break;
        }
      }
      prevParams.current = pageParams;
    }
    setDuplicateCallGuard(false);
    }, [load, person ?? channel, mediaType]);    

    useEffect(() => {
      setContent([]);
      setLoad(!load);
    }, [categoryFilter]);

    useEffect(() => {
        if (requestReturn.currentPage !== undefined) {
            setTotalPages(requestReturn.totalPages === 0 ? 1 : requestReturn.totalPages);
            setPageNumber(requestReturn.currentPage === 0 ? 1 : requestReturn.currentPage);
        }
        if ((contentType === "homepage" || contentType === "subscription") && (navbarFilter !== prevNavbarFilter.current || mediaType !== prevMediaType.current)) {
            if (navbarFilter !== prevNavbarFilter.current) {
              const contentTemp = requestReturn.data;
              contentTemp && setContent(contentTemp);
              prevNavbarFilter.current = navbarFilter;
            } else if (mediaType !== prevMediaType.current) {
              const contentTemp = requestReturn.data;
              contentTemp && setContent(contentTemp);
              prevMediaType.current = mediaType;
              activeIndexRef.current = -1;
            }
        } else {
          const contentTemp = content.length === 0 ? requestReturn.data : content.map((item) => item).concat(requestReturn.data);
          contentTemp && setContent(contentTemp);
        }
        setDuplicateCallGuard(true)
    }, [requestReturn, content && contentType]);

    useEffect(() => {
      if (content?.length !== 0) {
        dispatch(HasLoadedAction(true));
        setHasContentRow && setHasContentRow(true);
      }
    }, [content]);

    useEffect(() => {
      if (loadNext) {
        triggerPagination();
      }
    }, [loadNext]);

  const triggerPagination = () => {
    if (mediaType === prevMediaType.current && activeIndexRef.current !== 0) {
      totalPages >= pageNumber && setPageNumber(pageNumber + 1);
      setLoad(!load);
      dispatch(LoadNextAction(false));
    }
  };

  if (loadNext) triggerPagination();

  const linkToHome = () => {
    dispatch(GetCategoryFilter(category));
    navigate("/");
  };

  return (
    
    content.length !== 0 && (
    <div className={title ? `row ${'home__page_separation_' + type} ${(contentType == "homepage" || contentType === "subscription") && content.length === 0 ? 'row__extended' : ''}` : "row_no_title"}>
      {((contentType === "homepage" || contentType === "subscription") && content.length !== 0 && title != translation.Home_MightLike) && (
        <div className={title ? "row__title" : ""}>
          {(Logo !== undefined || category !== undefined) ? (
            <>
              {Logo && <Logo size={34} color="#B4AB8F" className="row__title--img" />}{" "}
              {(type === ("Categories") && page === "subscription") ? (
                <div className="row__title" 
                // onClick={() => linkToHome()}
                >
                  <h2 style={Logo ? {} : { margin: 0 }}>{title}</h2>
                  {sponsoredLogoPath && sponsoredLogoPath.trim() !== "" ? (
  sponsoredLogoExternalLink && sponsoredLogoExternalLink.trim() !== "" ? (
    <a href={sponsoredLogoExternalLink} target="_blank" rel="noopener noreferrer">
      <img src={sponsoredLogoPath} className="rowlogo" alt="pic" />
    </a>
  ) : (
    <img src={sponsoredLogoPath} className="rowlogo" alt="pic" />
  )
) : null}

                              
                    {" "}
                </div>
              ) : (
             <>   <h2> {title } </h2>
                
                {sponsoredLogoPath && sponsoredLogoPath.trim() !== "" ? (
  sponsoredLogoExternalLink && sponsoredLogoExternalLink.trim() !== "" ? (
    <a href={sponsoredLogoExternalLink} target="_blank" rel="noopener noreferrer">
      <img src={sponsoredLogoPath} className="rowlogo" alt="pic" />
    </a>
  ) : (
    <img src={sponsoredLogoPath} className="rowlogo" alt="pic" />
  )
) : null}

 
               </>                
                 
                
              )}
            </>
          ) : (
            <> <h2>{title}
            {sponsoredLogoPath && sponsoredLogoPath.trim() !== "" ? (
  sponsoredLogoExternalLink && sponsoredLogoExternalLink.trim() !== "" ? (
    <a href={sponsoredLogoExternalLink} target="_blank" rel="noopener noreferrer">
      <img src={sponsoredLogoPath} className="rowlogo" alt="pic" />
    </a>
  ) : (
    <img src={sponsoredLogoPath} className="rowlogo" alt="pic" />
  )
) : null}


     
            </h2></>
          )}
        </div>
        )}
        {(content !== null && content !== undefined && content.length !== 0 && title != translation.Home_MightLike) && (
            <div className="row__cards">
            <>
              {appearanceType === "row" ? (
              <Swiper
                  modules={[Navigation, Pagination]}
                  spaceBetween={30}
                  centeredSlidesBounds={true}
                  slidesPerView={4}
                  height={`${window.innerHeight * 0.6}px`}
                  pagination={{
                      clickable: true,
                      el: ".slider-pagination",
                      type: "bullets",
                  }}
                  className={type === "TopVideos" ? "mySwiper" : ""}
                  breakpoints={{
                    350: {
                        width: 350,
                        slidesPerView: 1,
                    },
                    360: {
                        width: 360,
                        slidesPerView: 1,
                    },
                    375: {
                        width: 340,
                        slidesPerView: 1,
                    },
                    390: {
                        width: 355,
                        slidesPerView: 1,
                    },
                    393: {
                        width: 393,
                        slidesPerView: 1,
                    },
                    412: {
                        width: 412,
                        slidesPerView: 1,
                    },
                    414: {
                        width: 414,
                        slidesPerView: 1,
                    },
                    640: {
                        width: 640,
                        slidesPerView: 2,
                    },
                    768: {
                        width: 768,
                        slidesPerView: 2,
                    },
                    1268: {
                        width: 1268,
                        slidesPerView: 3,
                    },
                    1400: {
                        width: 1400,
                        slidesPerView: 4,
                    },
                    1900: {
                        width: 1900,
                        slidesPerView: 5,
                    },
                    2768: {
                        width: 2768,
                        slidesPerView: 6,
                    },
                    3768: {
                        width: 3768,
                        slidesPerView: 7,
                    },
                    4768: {
                        width: 4768,
                        slidesPerView: 8,
                    },
                  }}
                  navigation
                  onBreakpoint={breakpoint => {
                      const slidePer = breakpoint.originalParams.breakpoints;
                      nbSlidesRef.current = slidePer[breakpoint.currentBreakpoint]?.slidesPerView;
                  }}
                  onNavigationPrev={swiper => {
                      swiper.allowSlideNext = true;
                  }}
                  onSlideChange={swiper => {
                      activeIndexRef.current++;
                      if (activeIndexRef.current === -1) {
                          activeIndexRef.current = 0;
                          swiper.activeIndex = 0;
                          swiper.slideTo(0);
                      }
                      if (
                          (
                              swiper.activeIndex <= pageParams.limit - nbSlidesRef.current ? swiper.activeIndex :
                              swiper.activeIndex - activeIndexRef.current * (pageParams.limit - nbSlidesRef.current)) === pageParams.limit - nbSlidesRef.current
                          ) {
                              activeIndexRef.current++;
                              triggerPagination();
                          }
                      }}
                  >
                    {content.length !== 0 && content?.map((item, index) => (
                      <SwiperSlide key={index}>
                          <VideoCard
                              item={item}
                              userId={userId}
                              appearanceType={appearanceType}
                              contentType={contentType}
                              isSaved={item.isSaved}
                              type={type}
                              index={index}
                              playlistDeleteContent={playlistDeleteContent}
                              typePlayList={typePlayList}
                              isPlayList={isPlayList}
                              playListCatType={playListCatType}
                          />
                      </SwiperSlide>
                  ))}
                  </Swiper>
              ) : (
                  <>
                    {content.length !== 0 && content?.map((item, index) => (
                      <VideoCard
                        item={item}
                        userId={userId}
                        appearanceType={appearanceType}
                        contentType={contentType}
                        isSaved={item.isSaved}
                        ind={index}
                        playlistDeleteContent={playlistDeleteContent}
                        typePlayList={typePlayList}
                        isPlayList={isPlayList}
                        playListCatType={playListCatType}
                      />
                    ))}
                </>
              )}
            </>
        </div>
      )}
    </div>
  )
)};

Row.propTypes = {
    title: PropTypes.any,
    savedContent: PropTypes.any,
    type: PropTypes.any,
    appearanceType: PropTypes.any,
    content: PropTypes.any,
    Logo: PropTypes.any,
};

export default memo(Row);
