import * as types from "../constant";

export function setIsSelectingAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.SET_IS_SELECTING, payload });
  };
}

export function setSelectedContentsAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.SET_SELECTED_CONTENTS, payload });
  };
}

export function setRunAIComparisonAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.SET_RUN_AI_COMPARISON, payload });
  };
}

export function setCanRunComparisonAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.CAN_RUN_COMPARISON, payload });
  }
}

export function setAIResultAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.SET_AI_RESULTS, payload });
  };
}

export function setIsGenerating(payload) {
  return async (dispatch) => {
    dispatch({ type: types.SET_IS_GENERATING, payload });
  };
}

export function setAiLanguage(payload) {
  return async (dispatch) => {
    dispatch({ type: types.SET_AI_LANGUAGE, payload });
  };
}