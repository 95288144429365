import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { DataUsageTwoTone } from "@material-ui/icons";
import requests from "../axios/Requests";
import { useDispatch, useSelector } from "react-redux";
import { patchPerson } from "../../servicesRedux/SpeakerService";
import { GetAllSpeakersAction } from "../../redux/action/GlobalActions";
import { SpeakerPreview } from "../smallComponents/SpeakerPreview";
import Button from "@mui/material/Button";
import azure from "../../components/UploadApp/build/azure-storage.blob";
import Avatar from "@mui/material/Avatar";

import "./ModalForm.scss";
const ModalForm = (props) => {
  const {
    open,
    onClose,
    title,
    subTitle,
    contentType,
    handleSubmitModal,
    data,
    modifyTag,
    isNewTag,
    setModifyTag,
  } = props;


  const [category, setCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [subSubCategory, setSubsubCategory] = React.useState("");
  const [speaker, setSpeaker] = React.useState("");
  const [channel, setChannel] = React.useState("");
  const [tags, setTags] = React.useState("");
  const [changedTag, setChangedTag] = React.useState("");
  const [elementPictureNAME, setElementPictureNAME] = React.useState(null);
  const [elementPicture, setElementPicture] = React.useState(null);
  const [picture, setPicture] = React.useState(null);
  const [pictureFullURL, setPictureFullURL] = React.useState(null);
  const [speakerObject, setSpeakerObject] = React.useState(data?.persons);
  const [channelObject, setChannelObject] = React.useState(data);
  const [categoryObject, setCategroyObject] = React.useState(data);
  const [errors, setErrors] = React.useState({});
  const [canUpload, setCanUpload] = React.useState(false);
  const { allSpeakers } = useSelector((state) => state.globalReducer);
  const { translation } = useSelector((state) => state.languageReducer);
  const dispatch = useDispatch();
  const [logoClear, setLogoClear] = React.useState(false);


  const date = new Date();
  const handleChangeThumbnailFile = (event) => {
    const targetId = event.target.id; 
    const targetName = event.target.name; 
    setElementPictureNAME(event.target.value.split("\\").pop());
    setElementPicture(event.target.files[0]);

    const file = event.target.files[0];
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = function () {


        if (targetId!="sponsored-logo-upload" &&( img.width < 500 || img.height < 500)) {
            setCanUpload(false);
            alert("The image is smaller than the 500x500px limit and will not be uploaded.");
        }
        else if ( targetId=="sponsored-logo-upload" ||(img.width >= 500 || img.height >= 500) && (img.width === img.height)) {
        const maxDimension = Math.max(img.width, img.height);
        const ratio = 500 / maxDimension;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const width = Math.round(img.width * ratio);
        const height = Math.round(img.height * ratio);
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        const resizedImageData = canvas.toDataURL();
        setPicture(resizedImageData);

        const binaryString = window.atob(resizedImageData.split(",")[1]);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        // create a file
        const file = new File([bytes], "resizedImage.jpeg", {
          type: "image/jpeg",
        });
        setElementPicture(file);
        setCanUpload(true);

        setLogoClear(false);
        }
     else {
    setCanUpload(false);
    alert("The image aspect ratio is not 1:1 and will not be uploaded.")
}
    };
  };

  const handleClearLogo = () => {
    // Reset the logo state to null or default


    setPicture(null);
    data.sponsoredLogoPath=null;
    setLogoClear(true);
  };

  function getTimestamp() {
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    return year + month + day + hour + minute + second;
  }

  function getFilenameFromUrl(url) {
    // Use the "lastIndexOf" method to find the position of the last slash in the URL
    var lastSlashIndex = url.lastIndexOf('/');

    // Check if a slash was found
    if (lastSlashIndex !== -1) {
      // Use the "substring" method to return the part of the URL after the last slash
      return url.substring(lastSlashIndex + 1);
    } else {
      // If no slash was found, return the original URL
      return url;
    }
  }



  const uploadPictureToBlob = (response, type, newThumbnailName) => {
  
    let file = elementPicture;
    let blobUri;
    let containerName;
    let sasToken;
    let blobService;
    let customBlockSize;
    let finishedOrError;
    let speedSummary;
    let newFile;

    if (response?.accountUrl === undefined || response?.containerName === undefined || response?.sasToken === undefined)
      return;

    if (!canUpload)
      return;

    switch (type) {
      case "speaker":

        blobUri = response.accountUrl;
        containerName = response.containerName;
        sasToken = response.sasToken;
        blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
        customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        finishedOrError = false;

        newFile = new File([file], newThumbnailName);

        speedSummary = blobService.createBlockBlobFromBrowserFile(
          containerName,
          "persons" + "/pictures/" + newThumbnailName,
          newFile,
          { blockSize: customBlockSize },
          function (error, result, response) {
            finishedOrError = true;
            if (error) {
              alert("Error when uploading Thumbnail");

              setErrors({
                ...errors,
                thumbnailError: error,
              });
            }
            //else {
            //alert("Thumbnail Uploaded Successfully");
            //}
          }
        );
        break;
      case "channel":

        blobUri = response.accountUrl;
        containerName = response.containerName;
        sasToken = response.sasToken;
        blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
        customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        finishedOrError = false;

        newFile = new File([file], newThumbnailName);

        speedSummary = blobService.createBlockBlobFromBrowserFile(
          containerName,
          "channels" + "/logos/" + newThumbnailName,
          newFile,
          { blockSize: customBlockSize },
          function (error, result, response) {
            finishedOrError = true;
            if (error) {
              alert("Error when uploading Thumbnail");

              setErrors({
                ...errors,
                thumbnailError: error,
              });
            }
            //else {
            //alert("Thumbnail Uploaded Successfully");
            //}
          }
        );
        break;
      case "updateSpeaker":
        const imgFileNameOnBlob = response?.data?.persons?.image ? getFilenameFromUrl(response?.data?.persons?.image) : '';
        blobUri = response.accountUrl;
        containerName = response.containerName;
        sasToken = response.sasToken;
        blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
        customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        finishedOrError = false;

        newFile = new File([file], imgFileNameOnBlob);
        blobService.deleteBlob(containerName, "persons" + "/pictures/" + imgFileNameOnBlob, function (error) {
          if (error) {
            // Handle the error
            console.error('An error occurred while deleting the blob:', error);
          } else {
            // The blob was deleted successfully
            console.log('The blob was deleted successfully');

            speedSummary = blobService.createBlockBlobFromBrowserFile(
              containerName,
              "persons" + "/pictures/" + imgFileNameOnBlob,
              newFile,
              { blockSize: customBlockSize },
              function (error, result, response) {
                finishedOrError = true;
                if (error) {
                  alert("Error when uploading Thumbnail");

                  setErrors({
                    ...errors,
                    thumbnailError: error,
                  });
                }
              }
            );
          }
        });
        break;
      case "updateChannel":
        const imgFileNameOnBlobChan = response?.data?.avatar ? getFilenameFromUrl(response?.data?.avatar) : '';
        blobUri = response.accountUrl;
        containerName = response.containerName;
        sasToken = response.sasToken;
        blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
        customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        finishedOrError = false;

        newFile = new File([file], imgFileNameOnBlobChan);
        blobService.deleteBlob(containerName, "channels" + "/logos/" + imgFileNameOnBlobChan, function (error) {
          if (error) {
            // Handle the error
            console.error('An error occurred while deleting the blob:', error);
          } else {
            // The blob was deleted successfully
            speedSummary = blobService.createBlockBlobFromBrowserFile(
              containerName,
              "channels" + "/logos/" + imgFileNameOnBlobChan,
              newFile,
              { blockSize: customBlockSize },
              function (error, result, response) {
                finishedOrError = true;
                if (error) {
                  alert("Error when uploading Thumbnail");

                  setErrors({
                    ...errors,
                    thumbnailError: error,
                  });
                }
              }
            );
          }
        });
        break;

        case "updateCategory":
          const imgFileNameOnBlobChan1 = response?.data?.sponsoredLogoPath ? getFilenameFromUrl(response?.data?.sponsoredLogoPath) : '';
          blobUri = response.accountUrl;
          containerName = response.containerName;
          sasToken = response.sasToken;
          blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
          customBlockSize =
            file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
          blobService.singleBlobPutThresholdInBytes = customBlockSize;
          finishedOrError = false;
  
          newFile = new File([file], imgFileNameOnBlobChan1);
          blobService.deleteBlob(containerName, "category" + "/logos/" + imgFileNameOnBlobChan1, function (error) {
            if (error) {
              // Handle the error
              speedSummary = blobService.createBlockBlobFromBrowserFile(
                containerName,
                "category" + "/logos/" + imgFileNameOnBlobChan1,
                newFile,
                { blockSize: customBlockSize },
                function (error, result, response) {
                  finishedOrError = true;
                  if (error) {
                    alert("Error when uploading category");
  
                    setErrors({
                      ...errors,
                      thumbnailError: error,
                    });
                  }
                }
              );
            } else {
              // The blob was deleted successfully
              speedSummary = blobService.createBlockBlobFromBrowserFile(
                containerName,
                "category" + "/logos/" + imgFileNameOnBlobChan1,
                newFile,
                { blockSize: customBlockSize },
                function (error, result, response) {
                  finishedOrError = true;
                  if (error) {
                    alert("Error when uploading category");
  
                    setErrors({
                      ...errors,
                      thumbnailError: error,
                    });
                  }
                }
              );
            }
          });
          break;
    }
  }

  useEffect(() => {
    //copy the data to a temp variable
    let tempTag = { ...modifyTag };
    tempTag.label = modifyTag?.label ?? tags?.label;
    tempTag.label_fr = modifyTag?.label_fr ?? tags?.label_fr;
    tempTag && setTags(tempTag);
    tempTag && setChangedTag(tempTag);
  }, [modifyTag]);

  useEffect(() => {
    if (modifyTag?.label_fr === null)
      setChangedTag((prevState) => ({
        ...prevState,
        label_fr: null,
      }));
  }, [modifyTag]);

  useEffect(() => {
    if (contentType === "createTag") {
      setTags("");
    }
  }, [contentType]);

  const handleChangeCategoryLabel = (event) => {
    setCategory((prevState) => ({
      ...prevState,
      label: event.target.value,
    }));
  };
  const handleChangeCategoryDsc = (event) => {
    setCategory((prevState) => ({
      ...prevState,
      description: event.target.value,
    }));
  };

  //use handleChangeCategoryLabel as example and create the same for tags
  const handleChangeTagLabel = (event) => {
    setTags((prevState) => ({
      ...prevState,
      label: event.target.value,
    }));
  };
  //now with LabelFr
  const handleChangeTagLabelFr = (event) => {
    setTags((prevState) => ({
      ...prevState,
      label_fr: event.target.value,
    }));
  };
  const handleModifyTagLabel = (event) => {
    setChangedTag((prevState) => ({
      ...prevState,
      label: event.target.value,
    }));
  };
  //now with LabelFr
  const handleModifyTagLabelFr = (event) => {
    setChangedTag((prevState) => ({
      ...prevState,
      label_fr: event.target.value,
    }));
  };

  const handleChangeSubCategoryLabel = (event) => {
    setSubCategory((prevState) => ({
      ...prevState,
      label: event.target.value,
    }));
  };

  const handleChangeSubSubCategoryLabel = (event) => {
    setSubsubCategory((prevState) => ({
      ...prevState,
      label: event.target.value,
    }));
  };
  const handleChangeSubCategoryDsc = (event) => {
    setSubCategory((prevState) => ({
      ...prevState,
      description: event.target.value,
    }));
  };

  const handleChangeSubSubCategoryDsc = (event) => {
    setSubsubCategory((prevState) => ({
      ...prevState,
      description: event.target.value,
    }));
  };
  const handleChangeSpeakerTitle = (event) => {
    setSpeaker((prevState) => ({
      ...prevState,
      title: event.target.value,
    }));
  };
  const handleChangeSpeakerFirstName = (event) => {
    setSpeaker((prevState) => ({
      ...prevState,
      firstName: event.target.value,
    }));
  };
  const handleChangeSpeakerImage = (event) => {
    setSpeaker((prevState) => ({
      ...prevState,
      image: event.target.value,
    }));
  };
  const handleChangeChannelName = (event) => {
    setChannelObject((prevState) => ({
      ...prevState,
      Name: event.target.value,
    }));
  };
  const handleChangeCategoryLabel1 = (event) => {
    setCategroyObject((prevState) => ({
      ...prevState,
      Label: event.target.value,
    }));
  };
  
  const handleChangeCategorySponsoredLogoExternalLink = (event) => {

    const value = event.target.value;
    setCategroyObject({
     
      SponsoredLogoExternalLink: value,
    });
    data.sponsoredLogoExternalLink=value;

//    alert('The link must start with "https://"');
  }
   
  

  const handleBlurSponsoredLogoExternalLink = () => {
    const httpsRegex = /^https:\/\//;// Regex to match a valid URL
    const input = categoryObject?.SponsoredLogoExternalLink || data?.sponsoredLogoExternalLink;

    if (input == "") {
      return; // If the input is an empty string, exit the function early
    }
    if (!httpsRegex.test(input)) {
      alert('The link must start with "https://"');

      setCategroyObject({
      
        SponsoredLogoExternalLink: "",
      });
      data.sponsoredLogoExternalLink="";
      
    }
  };


  const handleChangeChannelDescirption = (event) => {
    setChannelObject((prevState) => ({
      ...prevState,
      Description: event.target.value,
    }));
  };

  const handleChangeSpeakerLastName = (event) => {
    setSpeaker((prevState) => ({
      ...prevState,
      lastName: event.target.value,
    }));
  };
  const handleChangeSpeakerBiography = (event) => {
    setSpeaker((prevState) => ({
      ...prevState,
      biography: event.target.value,
    }));
  };

  const handleChangeSpeakerUpdate = (event, id) => {
    switch (id) {
      case "Speaker title":
        setSpeakerObject({
          ...speakerObject,
          // Trimming any whitespace
          title: event.target.value,
        });
        break;
      case "Speaker first name":
        setSpeakerObject({
          ...speakerObject,
          // Trimming any whitespace
          firstName: event.target.value,
        });
        break;
      case "Speaker last name":
        setSpeakerObject({
          ...speakerObject,
          // Trimming any whitespace
          lastName: event.target.value,
        });
        break;
      case "Speaker biography":
        setSpeakerObject({
          ...speakerObject,
          // Trimming any whitespace
          biography: event.target.value,
        });
        break;
      default:
        break;
    }
  };

  const handleChangeChannelUpdate = (event, id) => {
    switch (id) {
      case "Channel Name":
        setChannelObject({
          ...channelObject,
          // Trimming any whitespace
          Name: event.target.value,
        });
        break;
      case "Channel Description":
        setChannelObject({
          ...channelObject,
          // Trimming any whitespace
          Description: event.target.value,
        });
        break;
      default:
        break;
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    padding: "3em",
  };

  const validationSchema = () =>
    Yup.object().shape({
      all: Yup.string().required("Required"),
    });
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('data',data)
    var channel = {
      id: data?.id,
      name: channelObject?.Name,
      description: channelObject?.Description,
      value: data?.id,
      label: channelObject?.Name,
      isActive: true
    };
    let result;
    switch (contentType) {
      case "speakerUpdate":
        let speakerToUpdate = speakerObject;

        const personUpdateName = "SPEAKER";
        const updatedThumbnailName = getTimestamp() + "_" + personUpdateName + "_" +
          date.getTime().toString() + Math.floor(Math.random() * 1000000).toString()
          + "." + elementPictureNAME?.split('.').pop();

        speakerToUpdate.avatar = "persons" + "/pictures/" + updatedThumbnailName;

        let toSend = {
          id: data.id,
          personId: data.personId,
          firstName: speakerObject.firstName,
          lastName: speakerObject.lastName,
          title: speakerObject.title,
          biography: speakerObject.biography,
          value: data.id,
          label: speakerObject.firstName + " " + speakerObject.lastName,
          image: speakerObject.image,
        }

        if (elementPicture && speakerObject?.image) {
          toSend.avatar = "persons" + "/pictures/" + getFilenameFromUrl(speakerObject.image);
        }

        if (elementPicture && !speakerObject?.image) {
          toSend.avatar = speakerToUpdate.avatar
        }

        if (!canUpload) {
          if (!speakerObject?.image)
            delete toSend.avatar;
          else
            toSend.avatar = "persons" + "/pictures/" + getFilenameFromUrl(speakerObject.image);
        }

        result = await handleSubmitModal(category, subCategory, subSubCategory, toSend);


        (speakerObject.image)
          ? uploadPictureToBlob(result, "updateSpeaker", updatedThumbnailName)
          : uploadPictureToBlob(result, "speaker", updatedThumbnailName);
        break;
      case "speaker":

        let speakerToSend = speaker;
        const personName = "SPEAKER";
        const newThumbnailName = getTimestamp() + "_" + personName + "_" +
          date.getTime().toString() + Math.floor(Math.random() * 1000000).toString()
          + "." + elementPictureNAME?.split('.').pop();
        elementPicture && (speakerToSend.avatar = "persons" + "/pictures/" + newThumbnailName);
        if (!canUpload) delete speakerToSend.avatar;
        result = await handleSubmitModal(category, subCategory, subSubCategory, speakerToSend);
        elementPicture && uploadPictureToBlob(result, "speaker", newThumbnailName);

        break;
      case "channel":

        const channelName = "CHANNEL";
        const newChannelThumbnailName = getTimestamp() + "_" + channelName + "_" +
          date.getTime().toString() + Math.floor(Math.random() * 1000000).toString()
          + "." + elementPictureNAME?.split('.').pop();
        elementPicture && (channel.avatar = "channels" + "/logos/" + newChannelThumbnailName);
        if (!canUpload) delete channel.avatar;
        result = await handleSubmitModal(category, subCategory, subSubCategory, channel)

        elementPicture && uploadPictureToBlob(result, "channel", newChannelThumbnailName);

        break;
      case "channelUpdate":
        let channelToUpdate = JSON.parse(JSON.stringify(channel));

        const channelUpdateName = "CHANNEL";
        const updatedLogoName = getTimestamp() + "_" + channelUpdateName + "_" +
          date.getTime().toString() + Math.floor(Math.random() * 1000000).toString()
          + "." + elementPictureNAME?.split('.').pop();
        channelToUpdate.avatar = "channels" + "/logos/" + updatedLogoName;
        let toSendChan = {
          id: data.id,
          active: true,
          name: channelToUpdate.name,
          description: channelToUpdate.description,
          value: data.id,
          label: channelToUpdate.name,
          avatar: channelToUpdate.avatar,
          updateAvatar: channel?.avatar ? true : false
        }
        if (elementPicture && channel?.avatar) {
          toSendChan.avatar = "channels" + "/logos/" + getFilenameFromUrl(channel.avatar);
        }

        if (elementPicture && !channel?.avatar) {
          toSendChan.avatar = channelToUpdate.avatar
        }

        if (!canUpload) {
          if (!channel?.avatar)
            delete toSendChan.avatar;
          else
            toSendChan.avatar = "channels" + "/logos/" + getFilenameFromUrl(channel.avatar);
        }
        
        result = await handleSubmitModal(category, subCategory, subSubCategory, toSendChan);
        (channel?.avatar)
          ? uploadPictureToBlob(result, "updateChannel", updatedLogoName)
          : uploadPictureToBlob(result, "channel", updatedLogoName);
        break;
        case "categoryUpdate":

        var category = {
          id: data?.value,
          label: categoryObject?.Label ?? data?.label,
          sponsoredLogoExternalLink: categoryObject?.SponsoredLogoExternalLink ?? data?.sponsoredLogoExternalLink,
          
         };

        
        const newCategoryNameName = getTimestamp() + "_" +  "CATEGORY" + "_" +
          date.getTime().toString() + Math.floor(Math.random() * 1000000).toString()
          + "." + elementPictureNAME?.split('.').pop();
        elementPicture && (category.sponsoredLogoPath = "category" + "/logos/" + newCategoryNameName);
        if (!canUpload) delete category.sponsoredLogoPath;
        if (logoClear)  category.sponsoredLogoPath="";

        result = await handleSubmitModal(category, subCategory, subSubCategory, channel)

        elementPicture && uploadPictureToBlob(result, "updateCategory", newCategoryNameName);

            
          break;
      case "subCategory":
        handleSubmitModal(category, subCategory, subSubCategory, speaker);
        break;
      case "subsubCategory":
        handleSubmitModal(category, subCategory, subSubCategory, speaker);
        break;
      case "createtags":
        if (tags !== {})
          handleSubmitModal(
            category,
            subCategory,
            subSubCategory,
            speaker,
            tags
          );

        break;
      case "modifytags":
        if (changedTag !== {})
          handleSubmitModal(
            category,
            subCategory,
            subSubCategory,
            speaker,
            changedTag
          );

        break;
      default:
        break;
    }
    setChangedTag({});
    setTags({});
    setModifyTag !== undefined && setModifyTag({});
    closeThisModal();
  };
  const getContentType = (contentType) => {
    switch (contentType) {
      case "category":
        return (
          <Box>
            <TextField
              required
              id="outlined-basic"
              label="Category label"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={handleChangeCategoryLabel}
            />
            <TextField
              required
              id="outlined-basic"
              label="Category description"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={handleChangeCategoryDsc}
            />
          </Box>
        );
      case "createtags":
        return (
          <Box>
            <TextField
              required
              id="outlined-basic"
              label="Tag Label English"
              variant="outlined"
              sx={{ width: "90%" }}
              value={tags?.label ?? ""}
              onChange={handleChangeTagLabel}
            />
            <TextField
              id="outlined-basic"
              label="Tag Label French"
              variant="outlined"
              sx={{ width: "90%" }}
              value={tags?.label_fr ?? ""}
              onChange={handleChangeTagLabelFr}
            />
          </Box>
        );
      case "modifytags":
        return (
          <Box>
            <TextField
              id="outlined-basic"
              label="Tag Label English"
              variant="outlined"
              sx={{ width: "90%" }}
              value={changedTag?.label}
              disabled={
                !(
                  modifyTag !== undefined &&
                  modifyTag.isNew &&
                  modifyTag.canModify
                )
              }
              onChange={handleModifyTagLabel}
            />
            <TextField
              id="outlined-basic"
              label="Tag Label French"
              variant="outlined"
              sx={{ width: "90%" }}
              value={changedTag?.label_fr}
              onChange={handleModifyTagLabelFr}
            />
          </Box>
        );
      case "subCategory":
        return (
          <Box sx={3}>
            <TextField
              required
              id="outlined-basic"
              label="Subcategory label"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={handleChangeSubCategoryLabel}
            />
            <TextField
              required
              id="outlined-basic"
              label="Subcategory description"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={handleChangeSubCategoryDsc}
            />
          </Box>
        );
      case "subsubCategory":
        return (
          <Box sx={3}>
            <TextField
              required
              id="outlined-basic"
              label="Sub-Subcategory label"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={handleChangeSubSubCategoryLabel}
            />
            <TextField
              required
              id="outlined-basic"
              label="Sub-Subcategory description"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={handleChangeSubSubCategoryDsc}
            />
          </Box>
        );
      case "speaker":
        return (
          <Box sx={3}>
            <TextField
              required
              id="outlined-basic"
              label="Speaker first name"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={handleChangeSpeakerFirstName}
            />
            <TextField
              required
              id="outlined-basic"
              label="Speaker last name"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={handleChangeSpeakerLastName}
            />
            <TextField
              required
              id="outlined-basic"
              label="Speaker title"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={handleChangeSpeakerTitle}
            />
            <TextField
              required
              id="outlined-basic"
              label="Speaker biography"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={handleChangeSpeakerBiography}
              minRows={2}
              multiline={true}
            />
            {/* Speaker image */}
            <div className="modal_picture">
              <Button className="modal_select-picture" variant="contained" component="label">
                Select picture
                <input
                  className="video__upload--modify"
                  id="thumbnail-upload"
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  style={{ display: "none" }}
                  onChange={handleChangeThumbnailFile}
                />
              </Button>
            </div>
            <div className="modal_picture-text">
              file (500x500px JPG) : {elementPictureNAME}
              {picture && <img src={picture} alt="pic" />}
            </div>
            <br />
            <div>
              <SpeakerPreview translation={translation} picture={picture} speaker={speaker} />
            </div>
          </Box>
        );
      case "channel":
        return (
          <Box sx={3}>
            <TextField
              required
              id="outlined-basic"
              label="Content owner name"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={handleChangeChannelName}
              value={channelObject?.Name || data?.name}
            />
            <TextField
              required
              id="outlined-basic"
              label="Description "
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={handleChangeChannelDescirption}
              minRows={2}
              multiline={true}
              value={channelObject?.Description || data?.description}
            />
            <div className="modal_picture">
              <Button className="modal_select-picture" variant="contained" component="label">
                SELECT LOGO
                <input
                  className="video__upload--modify"
                  id="thumbnail-upload"
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  style={{ display: "none" }}
                  onChange={handleChangeThumbnailFile}
                />
              </Button>
            </div>
            <div className="modal_picture-text">
              file (500x500px JPG) : {elementPictureNAME}
              {(picture || data?.avatar) && <img src={picture ? picture : data?.avatar} alt="pic" />}
            </div>
            <br />
            <p>preview : </p>
            <div className={"content_owner-wrapper-modal"}>
              <>
                <div>
                  <Avatar
                    alt="userName"
                    aria-haspopup="true"
                    src={picture ? picture : data?.avatar}

                  />
                </div>
                <div>
                  <p>{channelObject?.Name}</p>
                  <p>{translation.Content_Owner}</p>
                </div>
              </>
            </div>
          </Box>
        );
      case "channelUpdate":
        if (data?.id !== undefined) {
          return (
            <Box sx={3}>
              <TextField
                required
                id="outlined-basic"
                label="Content owner name"
                variant="outlined"
                sx={{ width: "90%" }}
                onChange={handleChangeChannelName}
                value={channelObject?.Name || data?.name}
              />
              <TextField
                required
                id="outlined-basic"
                label="Description "
                variant="outlined"
                sx={{ width: "90%" }}
                onChange={handleChangeChannelDescirption}
                minRows={2}
                multiline={true}
                value={channelObject?.Description || data?.description}
              />
              <div className="modal_picture">
                <Button className="modal_select-picture" variant="contained" component="label">
                  SELECT LOGO
                  <input
                    className="video__upload--modify"
                    id="thumbnail-upload"
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    style={{ display: "none" }}
                    onChange={handleChangeThumbnailFile}
                  />
                </Button>
              </div>
              <div className="modal_picture-text">
                file (500x500px JPG) : {elementPictureNAME}
                {(picture || data?.avatar) && <img src={picture ? picture : data?.avatar} alt="pic" />}
              </div>
              <br />
              <p>preview : </p>
              <div className={"content_owner-wrapper-modal"}>
                <>
                  <div>
                    <Avatar
                      alt="userName"
                      aria-haspopup="true"
                      src={picture ? picture : data?.avatar}

                    />
                  </div>
                  <div>
                    <p>{channelObject?.Name ?? data?.name}</p>
                    <p>{translation.Content_Owner}</p>
                  </div>
                </>
              </div>
            </Box>
          );
        } else {
          return <></>;
        }
        case "categoryUpdate":
          
          if (data?.value !== undefined) {
            return (
              <Box sx={3}>
                <TextField
                  required
                  id="outlined-basic"
                  label="Category Label"
                  variant="outlined"
                  sx={{ width: "90%" }}
                  onChange={handleChangeCategoryLabel1}
                  value={categoryObject?.Label || data?.label}
                  disabled
                />
              
                 <TextField               
                  id="outlined-basic"
                  label="Sponsored logo External link"
                  variant="outlined"
                  sx={{ width: "90%" }}
                  onChange={handleChangeCategorySponsoredLogoExternalLink}
                 // minRows={2}
                  multiline={true}
                  value={categoryObject?.SponsoredLogoExternalLink || data?.sponsoredLogoExternalLink}
                  onBlur={handleBlurSponsoredLogoExternalLink}
                /> 
                 <div className="modal_picture">
                  <Button className="modal_select-picture" variant="contained" component="label">
                    SELECT LOGO
                    <input
                      className="video__upload--modify"
                      id="sponsored-logo-upload"
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      style={{ display: "none" }}
                      onChange={handleChangeThumbnailFile}
                    />
                  </Button>
                </div>
                <div >
                  File : {elementPictureNAME}
                 
                  {((picture || data?.sponsoredLogoPath)&& !logoClear) && <img className="rowlogo" src={picture ? picture : data?.sponsoredLogoPath} alt="pic" />}
                 
                  
                </div>

                <div className="modal_picture">
                {  
                  ((picture || data?.sponsoredLogoPath) ) && 
                  <Button className="modal_select-picture" variant="contained" component="label" onClick={handleClearLogo}>
                    Clear Logo
                    
                   
                  </Button>}
                </div>
                <br />
             
                
              </Box>
            );
          } else {
            return <></>;
          }
      case "createContributor":
        return (
          <Box sx={3}>
            <TextField
              required
              id="outlined-basic"
              label="Speaker first name"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={(e) => {
                handleChangeSpeakerUpdate(e, "Speaker first name");
              }}
            // value={speakerObject.firstName || data.persons.firstName}
            />
            <TextField
              required
              id="outlined-basic"
              label="Speaker last name"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={(e) => {
                handleChangeSpeakerUpdate(e, "Speaker last name");
              }}
            // value={speakerObject.lastName || data.persons.lastName}
            />
            <TextField
              required
              id="outlined-basic"
              label="Speaker title"
              variant="outlined"
              sx={{ width: "90%" }}
              onChange={(e) => {
                handleChangeSpeakerUpdate(e, "Speaker title");
              }}
            // value={speakerObject.title || data.persons.title}
            />
            <TextField
              required
              id="outlined-basic"
              label="Speaker biography"
              variant="outlined"
              sx={{ width: "90%" }}
              minRows={2}
              multiline={true}
              onChange={(e) => {
                handleChangeSpeakerUpdate(e, "Speaker biography");
              }}
            // value={speakerObject.biography || data.persons.biography}
            />
          </Box>
        );
      case "speakerUpdate":
        if (data.persons.id !== undefined) {
          return (
            <Box sx={3}>
              <TextField
                required
                id="outlined-basic"
                label="Speaker first name"
                variant="outlined"
                sx={{ width: "90%" }}
                onChange={(e) => {
                  handleChangeSpeakerUpdate(e, "Speaker first name");
                }}
                value={speakerObject.firstName || data.persons.firstName}
              />
              <TextField
                required
                id="outlined-basic"
                label="Speaker last name"
                variant="outlined"
                sx={{ width: "90%" }}
                onChange={(e) => {
                  handleChangeSpeakerUpdate(e, "Speaker last name");
                }}
                value={speakerObject.lastName || data.persons.lastName}
              />
              <TextField
                required
                id="outlined-basic"
                label="Speaker title"
                variant="outlined"
                sx={{ width: "90%" }}
                onChange={(e) => {
                  handleChangeSpeakerUpdate(e, "Speaker title");
                }}
                value={speakerObject.title || data.persons.title}
              />
              <TextField
                required
                id="outlined-basic"
                label="Speaker biography"
                variant="outlined"
                sx={{ width: "90%" }}
                multiline={true}
                minRows={2}
                onChange={(e) => {
                  handleChangeSpeakerUpdate(e, "Speaker biography");
                }}
                value={speakerObject.biography || data.persons.biography}
              />
              <div className="modal_picture">
                <Button className="modal_select-picture" variant="contained" component="label">
                  Select picture
                  <input
                    className="video__upload--modify"
                    id="thumbnail-upload"
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    style={{ display: "none" }}
                    onChange={handleChangeThumbnailFile}
                  />
                </Button>
              </div>
              {speakerObject.image && !elementPicture ? (
                <div className="modal_picture-text">
                  Current picture :
                  {speakerObject.image && <img src={speakerObject.image} alt="pic" />}
                </div>
              ) : (
                <div className="modal_picture-text">
                  New file (500x500px JPG) : {elementPictureNAME}
                  {picture && <img src={picture} alt="pic" />}
                </div>
              )}
              <br />
              <div>
                <SpeakerPreview translation={translation} picture={picture ?? speakerObject.image} speaker={speaker} />
              </div>
            </Box>
          );
        } else {
          return <></>;
        }
      default:
        return null;
    }
  };

  useEffect(() => {
    setSpeakerObject(data?.persons);
  }, [data]);
  useEffect(() => {
    setChannelObject(data);
  }, [data]);

  useEffect(() => { 
    setCategroyObject(data);
  }, [data]);
  function closeThisModal() {
    setChangedTag({});
    setTags({});
    setSpeaker({});
    setChannel({});
    setCategory({});
    setSubCategory({});
    setSubsubCategory({});
    setElementPictureNAME("");
    setElementPicture("");
    setPicture("");
    onClose();
  }

  useEffect(() => {
    if (contentType === "modifytags") {
      let tempTag = { ...modifyTag };
      tempTag.label = modifyTag?.label;
      tempTag.label_fr = modifyTag?.label_fr;
      tempTag && setTags(tempTag);
      tempTag && setChangedTag(tempTag);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={closeThisModal} className="create--modal">
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
        <Typography sx={{ mt: 2 }}>{subTitle}</Typography>
        <IconButton
          aria-label="close"
          onClick={() => closeThisModal()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Formik
          initialValues={{
            submit: false,
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <form onSubmit={(e) => handleSubmit(e, contentType)}>
              {getContentType(contentType)}
              <button
                className="create--button create--button--modal"
                id="file-upload"
                type="submit"
              >
                Submit
              </button>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
export default ModalForm;
