import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useTracking } from "react-tracking";

import { destroySession } from "../../../redux/action/AuthentificationAction";
import { GetCategoryFilter } from "../../../redux/action/CategoryFilterAction";
import Login from "../login/Login";
import SearchBar from "../searchBar/SearchBar";

import BellNotification from "../../../assets/images/Bell-notification.svg";
import Notification from "../../../assets/images/Bell.svg";
import Search from "../../../assets/images/Search-icon.svg";
import logout from "../../../assets/images/logout.svg";
import useReplacer from "../../../hooks/replacer";
import Constants from "../../constants/Constants";
import DownloadProgressBar from "../../Download/DownloadProgressBar";
const DesktopNavigation = ({
  tenant,
  redirectTo,
  newProfileImage,
  publicConference,
}) => {
  const dispatch = useDispatch();
  const { translation } = useSelector(state => state.languageReducer);
  const { userRedux } = useSelector(state => state.authentificationReducer);
  const replacer = useReplacer();
  const [selected, setSelected] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const location = useLocation();
  const [, setAnchorEl] = useState(null);
  const [notifications] = useState([]);
  const tracking = useTracking();

  const handleYellowRectangle = index => {
    const newSelected = selected.map((_, i) => {
      if (i === index) {
        return true;
      } else {
        return false;
      }
    });
    setSelected(newSelected);
  };

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleLogout = () => {
    tracking.trackEvent(
      {
        UserEventId: Number(Constants.UserEvent.CLICK.enum),
        UserActionId: Number(Constants.UserAction.LOGOUT.enum),
      }
    );
    dispatch(destroySession("DESTROY_SESSION"));
    Cookies.remove("userID");
    Cookies.remove("Expire_in");
    Cookies.remove("Header_signature");
    Cookies.remove("Payload");
    Cookies.remove("role");
    Cookies.remove("roles");
    Cookies.remove("accountType");
    if (Cookies.get("saveId") !== "true") {
      Cookies.set("saveId", "false");
      Cookies.remove("password");
      Cookies.remove("email");
    }
    window.location.href = "/login";

  };

  return (
    <>
      <Grid container spacing={2} style={{
        margin: 0,
      }}>
        <Grid item xs={12} md={3} lg={3} className="sidebar " style={{
          paddingTop: 0,
          paddingLeft: 0,
        }}>
          <div className="sidebar_container">
            {tenant && (
              <img
                className="sidebar__logo"
                src={tenant?.logoURL}
                onClick={() => { dispatch(GetCategoryFilter('')); redirectTo('/') }}
              />
            )}
            {tenant?.isWhiteLabelCustomer && (
              <div className="sidebar__white-label">
                <p>by Top Cream</p>
              </div>
            )}</div>
        </Grid>
        <Grid item xs={12} md={6} lg={6} style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 0,
        }}>
          {tenant?.fullCustomerName &&
            <>
              <Link
                to="/my-top-cream"
                className={`t__nav_bar_my_top_cream_desktop t-nav ${selected[0] ? "sidebar__content__selected" : "sidebar__content"}`}
                onClick={e => handleYellowRectangle(0)}
              >
                <span style={{ marginLeft: '-5px' }}>{replacer(translation.Top_Nav_For_Me)}</span>
              </Link>
              <Link
                to="/my-subscriptions"
                className={`t__nav_bar_my_subscriptions_desktop t-nav ${selected[1] ? "sidebar__content__selected" : "sidebar__content"}`}
                onClick={e => handleYellowRectangle(1)}
              >
                <span style={{ marginLeft: '-5px' }}>{translation.Top_Nav_My_Subscriptions}</span>
              </Link>
              <Link
                to="/my-speakers"
                className={`t__nav_bar_my_speakers_desktop t-nav ${selected[2] ? "sidebar__content__selected" : "sidebar__content"}`}
                onClick={e => handleYellowRectangle(2)}
              >
                <span style={{ marginLeft: '-5px' }}>{translation.Top_Nav_My_Speakers}</span>
              </Link>
              <Link
                to="/playlists"
                className={`t__nav_bar_my_playlists_desktop t-nav ${selected[4] ? "sidebar__content__selected" : "sidebar__content"}`}
                onClick={e => handleYellowRectangle(e, 4)}
              >
                <span style={{ marginLeft: '-5px' }}>{translation.Top_Nav_Playlists}</span>
              </Link>
              
  
              <div className="progressbar-link_container">
                <Link
                  to="/downloaded"
                  className={`t__nav_bar_downloaded_desktop t-nav ${selected[5] ? "sidebar__content__selected" : "sidebar__content"}`}
                  onClick={e => handleYellowRectangle(5)}
                >
                  <span style={{ marginLeft: '-5px' }}>{translation.Top_Nav_Downloaded}</span>
                </Link>
                <DownloadProgressBar />
              </div>

            </>
          }
        </Grid>
        <Grid item xs={12} md={3} lg={3} className="top__nav-bar-right" style={{
          paddingTop: 5
        }}>
          <ul>
            {location.pathname.indexOf("search") === -1 &&
              <>
                <li>
                  <div className={`navbar--notifications navbar--notifications--extended ${location.pathname == '/' ? 'home__page--search-input' : ''}`}>
                    <SearchBar />
                  </div>
                </li>
                <li>
                  <div className="navbar--notifications navbar--notifications-search" onClick={() => redirectTo('/search')}>
                    <img src={Search} alt="search" />
                  </div>
                </li></>}
            <li>
              <div className="navbar--notifications navbar--notifications-notifyicon" onClick={handleClick}>
                {notifications.length > 1 ? <img src={BellNotification} alt="notifications" /> : <img src={Notification} alt="notifications" />}
              </div>
            </li>
            <li>
              <div className="navbar--notifications navbar--notifications--extended-extra">
                <Login publicConference={publicConference} user={userRedux} logout={logout} handleLogout={handleLogout} newProfileImage={newProfileImage} />
              </div>
            </li>
          </ul>
        </Grid>
      </Grid>
    </>
  );
};

export default DesktopNavigation;