import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setAIResultAction, setRunAIComparisonAction, setCanRunComparisonAction, setAiLanguage } from "../../redux/action/ExploreAiAction";
import { Select, MenuItem } from '@mui/material';
import './SelectAIExploreContent.scss';
import { fontFamily, fontWeight } from '@material-ui/system';

const SelectAIExploreContent = ({onClick, doesResultExist, translation}) => {
    const dispatch = useDispatch();
    const selectedContents = useSelector((state) => state.exploreAiReducer.selectedContents);
    const [selectedLanguage, setSelectedLanguage] = useState(translation.language === "fr" ? "French" : "English");

    const handleRunAIcomparison = () => {
        if(selectedContents.length === 0 || selectedContents.length === 1) return;
        dispatch(setAIResultAction(""))
        dispatch(setCanRunComparisonAction(true));
        dispatch(setRunAIComparisonAction(true));
    }

    const handleLanguageChange = (event) => {
        let language = event.target.value;
        setSelectedLanguage(language);
    };

    useEffect(() => {
        selectedLanguage && dispatch(setAiLanguage(selectedLanguage));
    }, [selectedLanguage])

    const selectStyles = {
        height: '24px',
        minHeight: '24px',
        color: 'inherit',
        fontSize: 'inherit',
        fontFamily: 'inherit',
        '.MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '& .MuiSelect-select': {
            padding: '0px 24px 0px 8px',
            height: '24px',
            minHeight: '24px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '16px',
            color: '#333',
            fontFamily: 'Barlow',
            fontWeight: 'Bolder'
        },
        '& .MuiSelect-icon': {
            color: 'inherit'
        }
    };

    const menuItemStyles = {
        fontSize: '16px',
        fontFamily: 'inherit',
        minHeight: '24px',
        padding: '4px 8px'
    };

    return (
        <div className="selectAIExploreContent">
            <div className="contentTitle">
                <div>
                    <span className="title">{translation.select_content_ai}</span>
                    {!doesResultExist && <button className="closeButton" onClick={onClick}>✕</button>}
                </div>
                <div className="infoAndSelected">
                    <div className='selectedCount_container'>
                        <div>
                            <span className="selectedCount">{translation.selected} </span>
                            <span className="selectedCount_number"> ({selectedContents.length})</span>
                        </div>
                        <div style={{marginLeft: '25px', fontSize: '16px'}}>
                            <Select
                                value={selectedLanguage}

                                onChange={handleLanguageChange}
                                variant="outlined"
                                sx={selectStyles}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            marginTop: '4px',
                                            boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                                        }
                                    }
                                }}
                            >
                                <MenuItem value="French" sx={menuItemStyles}>Français</MenuItem>
                                <MenuItem value="English" sx={menuItemStyles}>English</MenuItem>
                                <MenuItem value="Spanish" sx={menuItemStyles}>Español</MenuItem>
                            </Select>
                        </div>
                    </div>
                    
                    <div className='button_info_container'>
                        <button 
                            onClick={handleRunAIcomparison} 
                            className="nextButton" 
                            style={{ background: selectedContents.length > 1 ? "#514F4F" : "#b4b0a7" }}
                        >
                            {selectedContents.length > 1 ? "Go 🡒" : "🡒"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectAIExploreContent;