import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import Cookies from "js-cookie";
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { useTracking } from "react-tracking";

import { destroySession } from "../../../redux/action/AuthentificationAction";
import { GetCategoryFilter } from "../../../redux/action/CategoryFilterAction";
import Login from "../login/Login";
import SearchBar from "../searchBar/SearchBar";
import { theme } from "../../../hooks/theme";

import BellNotification from "../../../assets/images/Bell-notification.svg";
import Notification from "../../../assets/images/Bell.svg";
import Search from "../../../assets/images/Search-icon.svg";
import logout from "../../../assets/images/logout.svg";
import Constants from "../../../components/constants/Constants";
import useReplacer from "../../../hooks/replacer";
import DownloadProgressBar from "../../Download/DownloadProgressBar";

const MediumDesktopNavigation = ({
  tenant,
  redirectTo,
  newProfileImage,
  publicConference,
}) => {
  const dispatch = useDispatch();
  const { translation } = useSelector(state => state.languageReducer);
  const { userRedux } = useSelector(state => state.authentificationReducer);
  const replacer = useReplacer();
  const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();
  const [, setAnchorEl] = useState(null);
  const tracking = useTracking();

  const [notifications] = useState([]);
  const styles = {
    toolbarMargin: {
      ...theme.mixins.toolbar,
      marginBottom: "3em",
      [theme.breakpoints.down("md")]: {
        marginBottom: "2em",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "1.25em",
      },
    },
    logo: {
      height: "8em",
      [theme.breakpoints.down("md")]: {
        height: "7em",
      },
      [theme.breakpoints.down("xs")]: {
        height: "5.5em",
      },
    },
    logoContainer: {
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    tabs: {
      marginLeft: "auto",
      "& .MuiButtonBase-root.MuiTab-root": {
        fontSize: 20,
      },
      "& .Mui-selected": {
        backgroundColor: "transparent",
        color: "#000",
        opacity: 0.7,
        borderRadius: 2,
      },
    },
    tab: {
      ...theme.typography.tab,
      minWidth: 10,
      marginLeft: "25px",
      color: "white",
    },
    hamburgerMenuIcon: {
      height: "50px",
      width: "50px",
    },
    menuIconContainer: {
      marginLeft: "auto",
      color: '#000',
      "&:hover": {
        opacity: 1,
      },
    },
    appbar: {
      zIndex: theme.zIndex.modal + 1,
      backgroundColor: 'transparent',
      color: '#000',
      width: '100%',
      boxShadow: 'none',
      padding: '0 70px 0 70px ',
      zIndex: 1
    },
  };

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleLogout = () => {
    tracking.trackEvent(
      {
        UserEventId: Number(Constants.UserEvent.CLICK.enum),
        UserActionId: Number(Constants.UserAction.LOGOUT.enum),
      }
    );
    dispatch(destroySession("DESTROY_SESSION"));
    Cookies.remove("userID");
    Cookies.remove("Expire_in");
    Cookies.remove("Header_signature");
    Cookies.remove("Payload");
    Cookies.remove("role");
    Cookies.remove("roles");
    Cookies.remove("accountType");
    if (Cookies.get("saveId") !== "true") {
      Cookies.set("saveId", "false");
      Cookies.remove("password");
      Cookies.remove("email");
    }
    window.location.href = "/login";

  };

  return (
    <Grid container spacing={2} style={{
      margin: 0,
    }}>
      <Grid item xs={2} md={4} className="sidebar" style={{
        paddingTop: 0,
        paddingLeft: 0,
        paddingTop: 0,
        paddingLeft: 0,
        display: 'flex',
        alignItems: 'center'
      }}>
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          onOpen={() => setOpenDrawer(true)}
        >
          <Paper>
            <ul className="drawer__mobile--view">
              <div className="sidebar_container">
                {tenant && (
                  <>
                    <img
                      className="sidebar__logo"
                      src={tenant?.logoURL}
                      onClick={() => { dispatch(GetCategoryFilter('')); redirectTo('/') }}
                    />
                  </>
                )}
                {tenant?.isWhiteLabelCustomer && (
                  <div className="sidebar__white-label">
                    <p>by Top Cream</p>
                  </div>
                )}
              </div>
              {tenant?.fullCustomerName &&
                <>
                  <ListItem divider>
                    <ListItemText disableTypography>
                      <Link className="t__nav_bar_my_top_cream_medium_desktop" to="/my-top-cream" onClick={() => setOpenDrawer(false)}>
                        <span>{replacer(translation.Top_Nav_For_Me)}</span>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem divider>
                    <ListItemText disableTypography>
                      <Link className="t__nav_bar_my_subscriptions_medium_desktop" to="/my-subscriptions" onClick={() => setOpenDrawer(false)}>
                        <span>{translation.Top_Nav_My_Subscriptions}</span>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem divider>
                    <ListItemText disableTypography>
                      <Link className="t__nav_bar_my_speakers_desktop_medium_desktop" to="/my-speakers" onClick={() => setOpenDrawer(false)}>
                        <span>{translation.Top_Nav_My_Speakers}</span>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem divider>
                    <ListItemText disableTypography>
                      <Link className="t__nav_bar_my_playlists_desktop_medium_desktop" to="/playlists" onClick={() => setOpenDrawer(false)}>
                        <span>{translation.Top_Nav_Playlists}</span>
                      </Link>
                    </ListItemText>
                  </ListItem>
                  
                  <ListItem divider>
                    <ListItemText disableTypography>
                      <div className="progressbar-link_container">
                        <Link className="t__nav_bar_downloaded_desktop_medium_desktop" to="/downloaded" onClick={() => setOpenDrawer(false)}>
                          <span>{translation.Top_Nav_Downloaded}</span>

                        </Link>
                        <DownloadProgressBar />

                      </div>
                    </ListItemText>
                  </ListItem>
    
                </>
              }
            </ul>
          </Paper>
        </SwipeableDrawer>
        {tenant && (
          <>
            <IconButton
              sx={styles.menuIconContainer}
              onClick={() => setOpenDrawer(!openDrawer)}
              disableRipple
              style={{
                marginLeft: 0,
                paddingLeft: 0,
              }}
            >
              <MenuIcon sx={styles.hamburgerMenuIcon} style={{
                width: 30,
                marginTop: 3
              }} />
            </IconButton>
            <div className="sidebar_container">
              <img
                className="sidebar__logo"
                src={tenant?.logoURL}
                onClick={() => { dispatch(GetCategoryFilter('')); redirectTo('/') }}
              />
              {tenant?.isWhiteLabelCustomer && (
                <div className="sidebar__white-label">
                  <p>by Top Cream</p>
                </div>
              )}
            </div>
          </>
        )}
      </Grid>
      <Grid item xs={10} md={8} className="top__nav-bar-right top__nav-bar-right--extended top__nav-bar-right--medium-screen">
        <ul>
          {location.pathname.indexOf("search") === -1 &&
            <>
              <li>
                <div className={`navbar--notifications navbar--notifications--extended ${location.pathname == '/' ? 'home__page--search-input' : ''}`}>
                  <SearchBar />
                </div>
              </li>
              <li>
                <div className="navbar--notifications navbar--notifications-search" onClick={() => redirectTo('/search')}>
                  <img src={Search} alt="search" />
                </div>
              </li></>}
          <li>
            <div className="navbar--notifications navbar--notifications-notifyicon" onClick={handleClick}>
              {notifications.length > 1 ? <img src={BellNotification} alt="notifications" /> : <img src={Notification} alt="notifications" />}
            </div>
          </li>
          <li>
            <div className="navbar--notifications navbar--notifications--extended-extra">
              <Login publicConference={publicConference} user={userRedux} logout={logout} handleLogout={handleLogout} newProfileImage={newProfileImage} />
            </div>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};

export default MediumDesktopNavigation;